import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import HttpApi from 'i18next-http-backend';
import get from 'lodash/get';
import moment from 'moment';
import { initReactI18next } from 'react-i18next';
import SyncMeta from '@utils/SyncHelper';
import { request } from './utils/axios';
import { fetchFeaturesAndCountries } from './actions/LoginActions';
import { getHeaders, isAccessTokenAvailable } from './utils/common-methods';
import { history, URL_CONSTANTS } from './utils/history';
class AppLoader {
  constructor(reduxStore) {
    this.store = reduxStore;
  }
  initI18n() {
    const lng =
      localStorage.getItem('LangParams') === null
        ? localStorage.getItem('i18nextLng')
        : localStorage.getItem('LangParams');

    moment.locale(lng);
    return i18n
      .use(LanguageDetector) // use of LanguageDetector means that lng value should NOT be set in init options
      .use(initReactI18next)
      .use(HttpApi) // Registering the back-end plugin, should NOT have resources in init
      .init({
        returnNull: false,
        fallbackLng: 'en',
        debug: false,
        keySeparator: false, // we use content as keys
        nsSeparator: false, // we do not use namespaces
        interpolation: {
          escapeValue: false
        }
      });
  }
  async redirectAsPerLoggedInStatus() {
    if (isAccessTokenAvailable()) {
      await fetchFeaturesAndCountries(this.store.dispatch);
    }
    return false;
  }
  async load() {
    await this.redirectAsPerLoggedInStatus();
    await this.initI18n();
    return false;
  }
}
export { AppLoader };
