import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Tooltip, Select } from 'antd';
import amplitude from 'amplitude-js';
import { history, URL_CONSTANTS } from '@utils/history';
import { CustomSelect } from '../../common/CustomSelect/CustomSelect';
import { PropertySVGIcon } from '../mainSider/menuIcons';
import { AvatarComponent } from '../../common/Avatar/Avatar';
import { DropdownDefaultTitleDiv, DropdownDefaultTitle, DropdownDefaultPlacholder } from './styles';
import { CustomDropdown } from './commonComponents';

export const PropertySelect = (props) => {
  const { t } = useTranslation();
  const domElement = {};
  const { Option } = Select;
  const {
    setCurrentProp,
    currentOrg,
    isAllFieldLoaded,
    isRecommendationLoad,
    selectedProperty,
    properties
  } = props;

  const onPropertyChange = (val, options) => {
    const propId = options.key;
    let select = document.querySelector(
      '[data-testid=collapsedHeader-select-my_properties] .ant-select-selection-search-input'
    );
    select.blur();
    setCurrentProp(propId);
    let currrentOrgId = currentOrg.id;
    const currentProperty = properties.find((item) => item.id === propId);
    if (!currentProperty.total_area) {
      history.push(
        URL_CONSTANTS.CREATE_FIELDS({
          orgId: currrentOrgId,
          farmId: propId
        })
      );
    } else {
      history.push(URL_CONSTANTS.ALL_FIELDS({ orgId: currrentOrgId, farmId: propId }));
    }
    amplitude.getInstance().setUserProperties({
      PropertyId: propId,
      area: currentProperty.total_area ? currentProperty.total_area : 0
    });
  };

  return (
    <CustomSelect
      data-testid='collapsedHeader-select-my_properties'
      dropdownMatchSelectWidth={true}
      showSearch
      optionFilterProp='children'
      filterOption={(input, option) => {
        return option.props.name.toLowerCase().indexOf(input.toLowerCase()) >= 0;
      }}
      size='large'
      className='propertySelect'
      onSelect={onPropertyChange}
      bordered={false}
      disabled={!currentOrg.name || isAllFieldLoaded || isRecommendationLoad}
      margintop={0}
      value={
        selectedProperty.name ? (
          <Option
            className='orgSelectOption'
            key={selectedProperty.id}
            value={selectedProperty.id}
            name={selectedProperty.name}
            data-testid={`headers-property-${selectedProperty.id}`}
          >
            <div style={{ display: 'flex', padding: '10px 0' }}>
              <PropertySVGIcon style={{ paddingTop: '10px', color: '#198746' }} />
              <DropdownDefaultTitleDiv style={{ padding: '0 0 0 10px', color: '#198746' }}>
                <DropdownDefaultTitle>{t('Property')}</DropdownDefaultTitle>
                <DropdownDefaultPlacholder>
                  <Tooltip title={selectedProperty.name}>
                    <span>{selectedProperty.name}</span>
                  </Tooltip>
                </DropdownDefaultPlacholder>
              </DropdownDefaultTitleDiv>
            </div>
          </Option>
        ) : (
          'default'
        )
      }
      dropdownStyle={{ borderRadius: 8, left: 90, top: 60 }}
      style={{ width: 260, margin: '0 0.5em', display: 'inline-flex' }}
      dropdownRender={(menu) => <CustomDropdown menu={menu} t={t} label={'My Properties'} />}
      onFocus={() => {
        const orgElement = document.querySelector(
          '[data-testid="collapsedHeader-select-my_properties"]'
        );
        const selectedElement = orgElement.querySelector('.ant-select-selection-item');
        domElement.propertyDropDown = selectedElement.cloneNode(true);
        domElement.propertyDropDown.id = 'cloned_property_div';
      }}
      onInputKeyDown={() => {
        const selectItem = document.getElementById('cloned_property_div');
        if (!selectItem) {
          document
            .querySelector('[data-testid="collapsedHeader-select-my_properties"]')
            .querySelector('[class="ant-select-selector"]')
            .appendChild(domElement.propertyDropDown);
        }
      }}
      onBlur={() => {
        const selectItem = document.getElementById('cloned_property_div');
        if (selectItem) {
          selectItem.remove();
        }
      }}
    >
      {!currentOrg.name && (
        <Option hidden key='default' name={'default'} value='default'>
          <div style={{ display: 'flex', padding: '10px 0' }}>
            <AvatarComponent
              style={{ height: 55, width: 40 }}
              src={''}
              alt={t('Organization Image')}
              fontIconName='company'
              fontIconSize='28'
              fontIconColor='#198746'
              fontIconStyle={{ lineHeight: '52px' }}
              color='#FFFFFF'
            />
            <DropdownDefaultTitleDiv>
              <DropdownDefaultTitle> {t('Properties')}</DropdownDefaultTitle>
              <DropdownDefaultPlacholder>{t('Please Select Property')}</DropdownDefaultPlacholder>
            </DropdownDefaultTitleDiv>
          </div>
        </Option>
      )}
      {properties.map((item) => (
        <Option
          className='orgSelectOption'
          key={item.id}
          value={item.id}
          name={item.name}
          data-testid={`headers-property-${item.id}`}
        >
          <div style={{ display: 'flex', padding: '10px 0' }}>
            <PropertySVGIcon style={{ paddingTop: '10px', color: '#198746' }} />
            <DropdownDefaultTitleDiv style={{ padding: '0 0 0 10px' }}>
              <DropdownDefaultTitle className='propertyTitle'>{t('Property')}</DropdownDefaultTitle>
              <DropdownDefaultPlacholder title={item.name} className='selectedProperty'>
                {item.name}
              </DropdownDefaultPlacholder>
            </DropdownDefaultTitleDiv>
          </div>
        </Option>
      ))}
    </CustomSelect>
  );
};

PropertySelect.propTypes = {
  setCurrentProp: PropTypes.any,
  isAllFieldLoaded: PropTypes.any,
  isRecommendationLoad: PropTypes.any,
  selectedProperty: PropTypes.any,
  properties: PropTypes.arrayOf(
    PropTypes.shape({
      created_at: PropTypes.string.isRequired,
      updated_at: PropTypes.string.isRequired,
      created_by_id: PropTypes.string.isRequired,
      last_updated_by_id: PropTypes.string.isRequired,
      org_id: PropTypes.string.isRequired,
      root_region_id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      time_zone: PropTypes.string.isRequired,
      country: PropTypes.string.isRequired,
      state: PropTypes.string.isRequired,
      city: PropTypes.string.isRequired,
      address: PropTypes.string.isRequired,
      zip_code: PropTypes.string.isRequired,
      place_id: PropTypes.string,
      total_area: PropTypes.number.isRequired,
      fields_updated_at: PropTypes.string.isRequired,
      reference_point: PropTypes.shape({
        type: PropTypes.string.isRequired,
        coordinates: PropTypes.arrayOf(PropTypes.number).isRequired
      }).isRequired,
      deleted: PropTypes.bool.isRequired,
      wait_response: PropTypes.bool.isRequired,
      crn: PropTypes.string.isRequired,
      regulatory_zone: PropTypes.string,
      id: PropTypes.string.isRequired
    })
  ).isRequired,
  currentOrg: PropTypes.shape({
    created_at: PropTypes.string.isRequired,
    updated_at: PropTypes.string.isRequired,
    created_by_id: PropTypes.string.isRequired,
    last_updated_by_id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    address: PropTypes.string.isRequired,
    city: PropTypes.string.isRequired,
    state: PropTypes.string.isRequired,
    country: PropTypes.string.isRequired,
    zip_code: PropTypes.string.isRequired,
    place_id: PropTypes.string,
    source: PropTypes.string.isRequired,
    currency: PropTypes.string.isRequired,
    unit_system: PropTypes.string.isRequired,
    phone: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    employer_identification_number: PropTypes.string,
    picture_url: PropTypes.string.isRequired,
    deleted: PropTypes.bool.isRequired,
    reference_point: PropTypes.shape({
      type: PropTypes.string.isRequired,
      coordinates: PropTypes.arrayOf(PropTypes.number).isRequired
    }).isRequired,
    crn: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
    coordinates: PropTypes.arrayOf(PropTypes.number).isRequired
  })
};
