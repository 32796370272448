import React from 'react';
import PropTypes from 'prop-types';
import { ThemeProvider } from 'styled-components';

const Themes = {
  defaultTheme: {
    colors: {
      titleColor: '#363948',
      inputBackground: '#F3F4F6',
      inputBorder: '#E8EAED',
      pageBackgroundSecondary: '#E5E5E5',
      pageBackgroundPrimary: '#FFFFFF',
      primary: '#73DC78',
      secondary: '#FFFFFF',
      btnGreen: '#73DC78',
      fontColor: '#73DC78',
      inputFontColor: '#363948',
      fontSecondaryColor: '#2F3031',
      focusRectangleColor: '#aab400',
      ErrorColor: 'red'
    },
    font: {
      family:
        'Rubik,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,Noto Sans,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol,Noto Color Emoji'
    },
    fontSizes: {
      small: '12px',
      medium: '14px',
      large: '20px',
      Xlarge: '27.7941px'
    }
  },
  newTheme: {
    colors: {},
    fonts: [],
    fontSizes: {}
  }
};

export const Theme = ({ themeName, children }) => (
  <ThemeProvider theme={Themes[themeName]}>{children}</ThemeProvider>
);

Theme.propTypes = {
  themeName: PropTypes.string.isRequired
};

export const toThemeHoc = (Component, themeName) => (props) =>
  (
    <Theme themeName={themeName}>
      <Component {...props} />
    </Theme>
  );
