import { Divider, Select } from 'antd';
import { sortBy } from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect, ConnectedProps, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { CustomSelect } from '@CustomSelect/CustomSelect';
import {
  getSelectedOrganization,
  getSelectedReseller,
  isMultifieldRecommendationLoad
} from '@selectors/headerSelectors';
import { useSolutionSelectFullList } from '@utils/featureConfigHooks';
import { makeDispatch } from '@utils/makeDispatch';
import { useQuery } from '../../hooks/useQuery';

import { listSolutionsForYear } from '@reducer/Seasons/listSolutionsForYear';
import {
  seedSelectorGetSolutionId,
  seedSelectorGetSolutionName
} from '@reducer/SeedSelector/selectors';
import { selectedSeason } from '@selectors/Seasons/selectedSeason';
import { organizationSolutionsForYear } from '@selectors/Seasons/solutions';
import { MultiRecommendationHeader } from '../../services/solutions/types';
import {
  DropdownDefaultPlacholder,
  DropdownDefaultTitle,
  DropdownDefaultTitleDiv,
  StyledDropdownDiv
} from './styles';

const { Option } = Select;

const mapStateToProps = (state: any) => {
  const selectedOrganizationId = getSelectedOrganization(state) as string;
  const selectedYear = selectedSeason(state);
  const selectedReseller = getSelectedReseller(state) as { email: string };
  return {
    selectedOrganizationId,
    selectedReseller,
    selectedYear,
    solutions:
      selectedOrganizationId && selectedYear
        ? organizationSolutionsForYear(state, selectedOrganizationId, selectedYear)
        : undefined
  };
};

const mapDispatchToProps = (dispatch: any) => ({
  fetchSolutionListForYear: makeDispatch(dispatch, listSolutionsForYear)
});

const connector = connect(mapStateToProps, mapDispatchToProps);

const renderDropdown = (menu, t) => (
  <StyledDropdownDiv className='dropDownRender'>
    <h3 style={{ padding: '8px 0 0 10px' }}>{t('solution')}</h3>
    {menu}
    <Divider />
  </StyledDropdownDiv>
);

type PropsFromRedux = ConnectedProps<typeof connector>;

/** The solution dropdown in the header, to be displayed on any page that uses solution data */
const SolutionSelectComponent = (props: PropsFromRedux) => {
  const history = useNavigate();
  const query = useQuery();
  const { t } = useTranslation();
  const selectedSolutionId = useSelector(seedSelectorGetSolutionId);
  const reduxSolutionName = useSelector(seedSelectorGetSolutionName);
  const canSeeFullSolutionList = useSolutionSelectFullList();
  const isRecommendationLoad = useSelector(isMultifieldRecommendationLoad);

  const { solutionId: pathSolutionId } = useParams<{
    [key: string]: string | undefined;
  }>();
  const {
    currentPage,
    fetchSolutionListForYear,
    selectedOrganizationId,
    selectedReseller,
    selectedYear,
    solutions
  } = props;

  const [selectedSolution, setSelectedSolution] = useState<MultiRecommendationHeader>();

  /**
   * Fetch solution list, as long as all required data is selected
   */
  const fetchSolutions = useCallback(() => {
    if (!selectedOrganizationId || !selectedYear || !selectedReseller.email) {
      // missing some of the data required to pull solution list
      return;
    }

    if (currentPage !== 'cropPage') {
      fetchSolutionListForYear({
        organizationId: selectedOrganizationId,
        year: selectedYear
      });
    }
  }, [fetchSolutionListForYear, selectedOrganizationId, selectedYear, selectedReseller.email]);

  /** Populate the list of solutions for this combination of user, org, and year */
  useEffect(() => {
    if (currentPage !== 'spotCheckPage') {
      fetchSolutions();
    }
    // fetchSolutions will change whenever one of its dependencies (org id, season, reseller) changes, which will trigger this effect to run
  }, [fetchSolutions]);

  useEffect(() => {
    if (!canSeeFullSolutionList) {
      // feature not enabled, don't need to pull full solution list
      return;
    }

    fetchSolutions();
    // don't include fetchSolutions callback in dependency list, as this effect is only for fetching the solutions list if the flag changes
  }, [canSeeFullSolutionList]);

  useEffect(() => {
    if (selectedSolutionId && solutions?.list && solutions?.list.length > 0) {
      const solution = solutions.list.find((s) => s.id === selectedSolutionId);
      if (solution) {
        setSelectedSolution(solution);
      } else {
        // solution is not currently in list, so fetch list; don't include callback in dependencies list, don't want to trigger this effect when other values change
        fetchSolutions();
      }
    } else {
      // no selected solution id, or solution list is empty or undefined
      setSelectedSolution(undefined);
    }
  }, [selectedSolutionId, setSelectedSolution, solutions]);

  // eslint-disable-next-line @typescript-eslint/no-unsafe-return
  const listOfSolutions = sortBy(solutions?.list, (s: any) => s.name);
  const multiFieldSolutions = listOfSolutions.filter(
    (item: any) => item.solution_type === 'multi_field'
  );
  const spotCheckSolutions = listOfSolutions.filter(
    (item: any) => item.solution_type === 'spot_check'
  );

  let valueVariable;
  if (selectedSolution) {
    valueVariable = selectedSolution.id;
  } else if (reduxSolutionName) {
    valueVariable = selectedSolutionId;
  } else {
    valueVariable = 'default';
  }
  const splitulrs = (currentPath, pathSolutionId) => {
    if (currentPath.includes('/crop')) {
      // SEED-2902 | when "new solution" option is selected from this dropdown, clear any query parameters out of the URL
      history(currentPath.split('?')[0]);
    } else if (currentPath.includes('/spot-check') && pathSolutionId) {
      history(currentPath.split(`/${pathSolutionId}`)[0]);
    }
  };
  const splitDeepUrl = (currentPath, pathSolutionId, value, currentQuery, querySolutionId) => {
    if (pathSolutionId) {
      /** the solution id is in the URL as a route parameter on the solution view and it _will_ be in spot check, so an existing
       * solution id in the location path should be replaced */
      const newPath = currentPath.replace(pathSolutionId, value);
      if (currentQuery) {
        /** if there are any query parameters on a page, we probably want to keep them, but this is not a necessary feature at this
         * time for any of the existing modules */
        history(`${newPath}${currentQuery}`);
      } else {
        history(newPath);
      }
    } else if (querySolutionId) {
      /** the solution id is a query parameter on the seed solution, so an existing solution id in the location search should be
       * replaced */
      history(`${currentPath}${currentQuery.replace(querySolutionId, value)}`);
    } else if (currentPath.includes('/crop')) {
      /** if on the seed selector module but do NOT have an existing solution loaded, add the query parameters for solution id and
       * season */
      history(`${currentPath}?solution_id=${value}&year=${selectedYear}`);
    } else if (currentPath.includes('/spot-check')) {
      /** if on the spot check module, but do NOT have an existing solution selected, push solution id onto the URL */
      history(`${currentPath}/${value}`);
    }
  };

  return (
    <CustomSelect
      disabled={isRecommendationLoad}
      size='large'
      className='solutionSelect'
      dropdownMatchSelectWidth={true}
      dropdownStyle={{
        borderRadius: 8,
        left: 90,
        top: 60
      }}
      style={{
        width: 260,
        margin: '0 0.5em',
        verticalAlign: 'middle',
        padding: '10px 0'
      }}
      value={valueVariable}
      dropdownRender={(menu) => renderDropdown(menu, t)}
      onSelect={(value: string | JSX.Element) => {
        if (typeof value !== 'string') {
          return;
        }
        const currentPath = location.pathname;
        if (value === 'default') {
          splitulrs(currentPath, pathSolutionId);
        } else {
          // value is solution id; select this solution and navigate to the correct URL so that the solution data loads into the page
          // some of our modules have different URL formats right now, but we want to support this feature on multiple of them
          const querySolutionId = query.get('solution_id');
          const currentQuery = location.search;
          splitDeepUrl(currentPath, pathSolutionId, value, currentQuery, querySolutionId);
        }
      }}
      margintop={'0'}
    >
      <Option key='default' value='default'>
        <DropdownDefaultTitleDiv className='dropdownDefaultTitleDiv'>
          <DropdownDefaultTitle>{t('solution')}</DropdownDefaultTitle>
          <DropdownDefaultPlacholder>{t('new solution')}</DropdownDefaultPlacholder>
        </DropdownDefaultTitleDiv>
      </Option>
      {canSeeFullSolutionList ? (
        <>
          {multiFieldSolutions.length > 0 && (
            <p
              style={{
                cursor: 'not-allowed',
                opacity: '0.7'
              }}
            >
              {t('field solution')}
            </p>
          )}
          {multiFieldSolutions.length > 0 &&
            multiFieldSolutions.map((item: any) => {
              return (
                <Option
                  key={item.id}
                  value={item.id}
                  name={item.name}
                  disabled={location.pathname.includes('/spot-check')}
                >
                  <DropdownDefaultTitleDiv className='dropdownDefaultTitleDiv'>
                    <DropdownDefaultTitle className='solutionTitle'>
                      {t('solution')}
                    </DropdownDefaultTitle>
                    <DropdownDefaultPlacholder id={`solution-${item.id}`}>
                      {item.name}
                    </DropdownDefaultPlacholder>
                  </DropdownDefaultTitleDiv>
                </Option>
              );
            })}
          {spotCheckSolutions.length > 0 && (
            <p
              style={{
                cursor: 'not-allowed',
                opacity: '0.7'
              }}
            >
              {t('spot solution')}
            </p>
          )}
          {spotCheckSolutions.length > 0 &&
            spotCheckSolutions.map((item: any) => {
              return (
                <Option
                  key={item.id}
                  value={item.id}
                  name={item.name}
                  disabled={location.pathname.includes('/crop')}
                >
                  <DropdownDefaultTitleDiv className='dropdownDefaultTitleDiv'>
                    <DropdownDefaultTitle className='solutionTitle'>
                      {t('solution')}
                    </DropdownDefaultTitle>
                    <DropdownDefaultPlacholder id={`solution-${item.id}`}>
                      {item.name}
                    </DropdownDefaultPlacholder>
                  </DropdownDefaultTitleDiv>
                </Option>
              );
            })}
        </>
      ) : (
        reduxSolutionName &&
        selectedSolutionId && (
          <Option key={selectedSolutionId} value={selectedSolutionId}>
            <DropdownDefaultTitleDiv className='dropdownDefaultTitleDiv'>
              <DropdownDefaultTitle>{t('solution')}</DropdownDefaultTitle>
              <DropdownDefaultPlacholder>{reduxSolutionName}</DropdownDefaultPlacholder>
            </DropdownDefaultTitleDiv>
          </Option>
        )
      )}
    </CustomSelect>
  );
};

export const SolutionSelect = connector(SolutionSelectComponent);
