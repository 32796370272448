import styled from 'styled-components';
import { Col, Layout, Select, List } from 'antd';

const { Header } = Layout;

export const StyledHeader = styled(Header)`
  width: 100%;
  /* display: flex;
  flex-direction: row;
  align-items: center; */
  display: flex;
  padding: 0;
  background-color: #ffffff;
  line-height: 40px;

  .Collapselogo {
    height: 82px;
    margin: 14px;
    transition: all 0.5s;
  }

  .ant-select-single .ant-select-selector::after {
    display: flex;
  }
  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    background-color: transparent;
    border: 0;
  }
  .ant-select-single .ant-select-selector {
    //display: inline-block;
  }
  .ant-select-show-search.ant-select:not(.ant-select-customize-input) .ant-select-selector {
    height: 100%;
    padding: 0;
    display: inline-block;
  }
  .ant-select-single .ant-select-selector .ant-select-selection-item {
    height: 100%;
    display: flex;
    padding: 0;
  }
  .ant-select-single.ant-select-show-arrow .ant-select-selection-search {
    height: 100%;
  }
  .ant-select-single.ant-select-lg:not(.ant-select-customize-input):not(.ant-select-customize-input)
    .ant-select-selection-search-input {
    height: 100%;
  }

  .ant-select-single.ant-select-selection-search-input {
    background: rgba(245, 248, 252, 0.77);
    padding: 10px 0px 10px 35px;
    margin: 6px 0px;
    height: 50px;
    width: 260px;
  }
  .ant-select-single.ant-select-lg:not(.ant-select-customize-input):not(.ant-select-customize-input)
    .ant-select-selection-search-input:focus {
    background: rgba(245, 248, 252, 0.77);
    padding: 4px 0px 0px 30px;
    margin: 5px 0px 0px 0px;
    height: 50px;
    width: 260px;
  }

  #cloned_org_div,
  #cloned_property_div,
  #cloned_reseller_div {
    opacity: 0.4;
  }
  #cloned_property_div {
    opacity: 0.4;
  }

  .propertySelect.ant-select-single.ant-select-lg:not(.ant-select-customize-input):not(
      .ant-select-customize-input
    )
    .ant-select-selection-search-input:focus {
    background: rgba(245, 248, 252, 0.77);
    padding: 3px 0px 0px 20px;
    margin: 5px 0px 0px 0px;
    height: 50px;
    width: 270px;
  }

  .ant-select-selection {
    display: block;
    box-sizing: border-box;
    background-color: #ffffff;
    border: 0px solid #e8eaed;
    border-top-width: 0;
  }
  .ant-avatar {
    background: transparent;
  }
  .ant-select {
    /* top: -5px; */
    height: 100%;
    display: inline-flex;
    color: #000000a6;
  }
  .ant-select-lg .ant-select-selection__rendered {
    /* line-height: 60px; */
    margin-left: 0;
    height: 100%;
  }
  .ant-select-lg .ant-select-selection--single {
    height: 100%;
  }
  .ant-select-selection-selected-value {
    float: left;
    /* max-width: 350px; */
    /* overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis; */
    height: 100%;
    display: flex !important;
    align-items: center;
    justify-content: center;
  }
  .ant-select-selection:focus,
  .ant-select-selection:active {
    border-right-width: 0px !important;
    outline: 0;
    box-shadow: none;
  }
  .ant-select-selection:hover {
    border-color: #25cce6;
    border-right-width: 0px !important;
  }
  .ant-select-search--inline .ant-select-search__field {
    background: #f5f8fcc4;
    /* background: #f5f8fc; */
    padding: 10px 0 10px 35px;
    margin: 6px 0;
    height: 50px;
    width: 260px;
  }
  .ant-select-selection--single .ant-select-selection__rendered {
    margin-right: 9px;
  }
  .ant-select-search:focus {
    outline: none;
  }
  .ant-select-focused .ant-select-selection,
  .ant-select-selection:focus,
  .ant-select-selection:active {
    border-color: #fff;
    box-shadow: none;
  }
  .ant-select-arrow {
    right: 5px;
  }
  .ant-badge {
    margin: 5px 0;
  }
  .ant-select-dropdown-menu-item {
    padding: 0;
    display: flex;
  }
  .ant-select-item-option-active:not(.ant-select-item-option-disabled) {
    background-color: #c3d9c7;
  }
  .rc-virtual-list-scrollbar-thumb {
    background: #009933 !important;
  }
  .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input)
    .ant-select-selector {
    border-color: transparent;
    box-shadow: unset;
    border-right-width: 0;
    outline: 0;
  }
`;

export const PopoverSelectStyle = styled(Select)`
  .ant-select-item-option-active:not(.ant-select-item-option-disabled) {
    background-color: #c3d9c7;
  }
`;

export const ListStyle = styled(List)`
  .ant-select-item-option-active:not(.ant-select-item-option-disabled) {
    background-color: #c3d9c7;
  }
  .ant-list-item {
    padding: 10px;
  }
`;

export const DropdownDefaultTitleDiv = styled.div`
  display: block;
  padding: 0px 2px;
  line-height: 1.6;
`;
export const DropdownDefaultTitle = styled.p`
  margin: 0;
  font-size: 12px;
  font-weight: normal;
  color: #707374;
`;

export const DropdownDefaultPlacholder = styled.p`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 195px;
  margin: 0;
  font-weight: 500;
  /* color: #2f3031; */
`;

export const StyledDropdownDiv = styled.div`
  width: 260px;
  /* max-height: 45vh; */
  /* min-height: 40vh; */
  /* height: calc(100vh - 238px); */

  background-color: #ffffff;
  border-radius: 5px;
  .orgtitle,
  .seasontitle,
  .solutionTitle,
  .propertyTitle {
    display: none;
  }
  .selectedOrg {
    margin-top: 5% !important;
  }
  .selectedProperty {
    margin-top: 8% !important;
  }

  .ant-divider-horizontal {
    margin: 8px 0;
  }

  .ant-select-dropdown-menu-item-selected {
    background-color: #f2f4f6;
  }
  .ant-avatar {
    background: transparent;
  }
  .ant-badge {
    margin: 5px 0;
  }
`;

export const StyledResellerDropdownDiv = styled.div`
  width: 260px;
  /* max-height: 45vh; */
  /* min-height: 40vh; */
  /* min-height: calc(100vh - 233px); */

  background-color: #ffffff;
  border-radius: 5px;
  .ant-divider-horizontal {
    margin: 10px 0 0;
  }
  .ant-select-dropdown-menu-item {
    padding: 5px;
  }
  .ant-select-dropdown-menu-item-selected {
    background-color: #f2f4f6;
  }
  .ant-avatar {
    background: transparent;
  }
`;

export const StyledImageContainer = styled(Col)`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px;
  display: inline-table;
`;

export const StyledInitialName = styled.span`
  height: 40px;
  width: 40px;
  border: 1px solid;
  border-radius: 40px;
  text-align: center;
  line-height: 40px;
  color: #707374;
  display: block;
`;

export const StyledAnchorLink = styled.a`
  color: rgba(0, 0, 0, 0.65);
  a:active,
  a:hover {
    color: #009933;
  }
`;
