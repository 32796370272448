import locale_bg from 'antd/es/locale/bg_BG';
import locale_cs from 'antd/es/locale/cs_CZ';
import locale_de from 'antd/es/locale/de_DE';
import locale_en from 'antd/es/locale/en_US';
import locale_enGB from 'antd/es/locale/en_GB';
import locale_es from 'antd/es/locale/es_ES';
import locale_fr from 'antd/es/locale/fr_FR';
import locale_hu from 'antd/es/locale/hu_HU';
import locale_it from 'antd/es/locale/it_IT';
import locale_pl from 'antd/es/locale/pl_PL';
import locale_pt from 'antd/es/locale/pt_BR';
import locale_ro from 'antd/es/locale/ro_RO';
import locale_ru from 'antd/es/locale/ru_RU';
import locale_sk from 'antd/es/locale/sk_SK';
import locale_sr from 'antd/es/locale/sr_RS';
import locale_tr from 'antd/es/locale/tr_TR';
import locale_uk from 'antd/es/locale/uk_UA';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

export const useLocale = () => {
  const { i18n } = useTranslation();
  moment.locale(i18n.language);
  switch (i18n.language) {
    case 'bg':
      return locale_bg;
    case 'cs':
      return locale_cs;
    case 'de':
      return locale_de;
    case 'en-GB':
      return locale_enGB;
    case 'es':
      return locale_es;
    case 'fr':
      return locale_fr;
    case 'hu':
      return locale_hu;
    case 'it':
      return locale_it;
    case 'pl':
      return locale_pl;
    case 'pt':
      return locale_pt;
    case 'ro':
      return locale_ro;
    case 'ru':
      return locale_ru;
    case 'sk':
      return locale_sk;
    case 'sr':
      return locale_sr;
    case 'tr-TR':
      return locale_tr;
    case 'uk':
      return locale_uk;
    default:
      return locale_en;
  }
};
