/* eslint-disable @typescript-eslint/no-floating-promises */
/* eslint-disable @typescript-eslint/no-unsafe-return */
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Modal } from 'antd';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';
import { fetchFeaturesAndCountries, USER_LOGIN_REQUEST_SUCCESS } from '@actions/LoginActions';
import { clearLocalStorage } from '@utils/common-methods';
import { logoutUser } from '@actions/user';
import { URL_CONSTANTS } from '@utils/history';
import {
  IBrandingState,
  setBrand,
  clearBrand,
  loadBrandingFromStorage
} from '@reducer/Branding/branding';
import { StyledSpin } from '../../common/Loader/Loader.style';
import { IAuthTokenResponseDTO } from '../../interfaces';
import {
  isCropwiseOAuth2Login,
  logoutSSOUserFromApp,
  trackSSOLogoutOperation
} from './CropwiseOAuth2';

type ExchangeResponse = {
  accessToken: string;
  redirect: string;
  issued: string;
  error: string;
  branding: IBrandingState | undefined;
};

/**
 * A component that exchanges an exchange token for an access token
 */
export const SsoConnector = () => {
  const history = useNavigate();
  const { exchangeToken } = useParams<{ exchangeToken: string }>();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);

  const handleLogout = () => {
    const branding = loadBrandingFromStorage();
    const isOAuth2SSOLogin = isCropwiseOAuth2Login();
    clearLocalStorage();
    dispatch(logoutUser());
    if (branding?.logoutRedirect) {
      window.location.href = branding.logoutRedirect;
    } else {
      if (isOAuth2SSOLogin) {
        trackSSOLogoutOperation();
        logoutSSOUserFromApp();
      } else {
        history(URL_CONSTANTS.LOGIN());
      }
    }
  };

  // On load, attempt to get an access token from the sso API
  useEffect(() => {
    const exchangeDispatch = async () => {
      const url = `${process.env.BATCH_PLANTING_API}/api/sso/exchange/${exchangeToken}`;
      try {
        // Issue the request
        const response = await axios.get<ExchangeResponse>(url);

        // If there's an error in the response, throw it
        if (response.data.error) {
          throw response.data.error;
        }

        // From here we have an access token, so store it in redux and the local storage
        const tokens: IAuthTokenResponseDTO = {
          access_token: response.data.accessToken,
          refresh_token: ''
        };
        dispatch({ type: USER_LOGIN_REQUEST_SUCCESS, payload: tokens });
        localStorage.setItem('tokens', JSON.stringify(tokens));

        await fetchFeaturesAndCountries(dispatch);

        // if we got branding details back, apply them
        if (response.data.branding) {
          dispatch(setBrand(response.data.branding));
        } else {
          dispatch(clearBrand(null));
        }
        // and redirect the user to their intended destination
        history(response.data.redirect);
      } catch (err) {
        // In the case of an error log and report it
        console.error(err, `Error authenticating with SSO: ${url}`);
        Modal.error({
          title: t('Error authenticating with SSO'),
          onOk: () => handleLogout()
        });
        setLoading(false);
      }
    };
    exchangeDispatch();
  }, [exchangeToken, history, t]);

  return <StyledSpin spinning={loading} />;
};
