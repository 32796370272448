import { Layout } from 'antd';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { getSelectedOrganization, isHeaderCollapsed } from '@selectors/headerSelectors';
import { getallOrgsList } from '@selectors/organizationListSelector';
import { isAdmin, isManager, isAlloweduserForSetting } from '@selectors/userProfileSelector';
import { iff } from '@utils/iff';
import { ErrorBoundary } from '../common/ErrorBoundary';
import { HeaderContainerComponent } from './header/container';
import { MainSider } from './mainSider/mainSider';
import { MapLayout } from '@layout/MapLayout';
import { ChervonLeftIcon, ChervonRightIcon } from '../features/CollapsibleSider';
import { StyledContentPageLayout } from '@layout/StyledContentPageLayout';
import { useMapContext } from '../features/GoogleMap/MapProvider';
import SupportButton from '@layout/Support';

const { Sider, Content } = Layout;
const StyledLayout = styled(Layout)`
  height: 100%;
`;
const CollapsibleSiderStyle = styled(Sider)`
  transition: all 0.5s;
  height: 100%;
  position: relative;
  background: #ffffff;
  .trigger {
    z-index: 993;
    width: 24px;
    height: 24px;
    padding: 0px;
    position: absolute;
    top: 45%;
    left: 99.7%;
    background-color: #f2f4f6;
    border: 0px;
    border-radius: 50%;
    box-shadow: rgb(9 30 66 / 8%) 0px 0px 0px 1px, rgb(9 30 66 / 8%) 0px 2px 4px 1px;
    color: #2f3031;
    cursor: pointer;
    outline: 0px;
    transition: background-color 100ms linear 0s, color 100ms linear 0s,
      opacity 350ms cubic-bezier(0.2, 0, 0, 1) 0s;
    transform: rotate(180deg);
    transform-origin: 7px center;
  }
`;
const StyledContent = styled(Content)`
  background-color: #e5e5e5;
  overflow-y: hidden;
  overflow-x: hidden;
  padding: 0px;
`;
const StyledChildren = styled.div`
  padding: 0px;
`;

export const PageLayout = (props) => {
  const isCollapsed = useSelector(isHeaderCollapsed);
  const isRoleManager = useSelector(isManager);
  const isRoleAdmin = useSelector(isAdmin);
  const isAllowedForSetting = useSelector(isAlloweduserForSetting);
  const orgId = useSelector(getSelectedOrganization);
  const orgs = useSelector(getallOrgsList);
  const currentOrg = orgs.find((o) => o.id === orgId) || {};
  const [windowHeight] = useState(window.innerHeight);
  const { mapCurrentInstance } = useMapContext();

  // Toggle collapse state
  const toggleCollapse = () => {
    setCollapsed(!collapsed);
    setTimeout(() => {
      mapCurrentInstance.invalidateSize();
    }, 1000);
  };
  const [collapsed, setCollapsed] = useState(false);
  const validPaths = ['spotCheckPage', 'cropPage', 'dealer', 'propertyPage'];
  const pageWidth = {
    spotCheckPage: 550, // Add width for spotCheckPage
    cropPage: 550, // Width for cropPage
    dealer: 500, // Add width for dealer
    propertyPage: 500 // Add width for propertyPage
  };
  return (
    <StyledLayout>
      <ErrorBoundary fromHeader={true}>
        <HeaderContainerComponent
          isCollapsed={isCollapsed}
          header={props.header}
          currentPage={props.currentPage}
        />
      </ErrorBoundary>
      <Layout className={'mainLayout'} style={{ marginLeft: 65, height: '100%' }}>
        <ErrorBoundary fromHeader={true}>
          <MainSider
            isAllowedForSetting={isAllowedForSetting}
            isRoleManager={isRoleManager}
            isRoleAdmin={isRoleAdmin}
            currentOrg={currentOrg}
          />
        </ErrorBoundary>
        {validPaths.includes(props.currentPage) && (
          <>
            <CollapsibleSiderStyle
              width={pageWidth[props.currentPage]}
              collapsed={collapsed}
              collapsedWidth='10'
            >
              {collapsed ? (
                <ChervonRightIcon
                  data-testid='rightIcon'
                  onClick={toggleCollapse}
                  className={'trigger'}
                />
              ) : (
                <ChervonLeftIcon
                  onClick={toggleCollapse}
                  data-testid='leftIcon'
                  className={'trigger'}
                />
              )}
              <StyledChildren>{props.content}</StyledChildren>
            </CollapsibleSiderStyle>
            <StyledContentPageLayout windowHeight={windowHeight} currentPage={props.currentPage}>
              <MapLayout currentPage={props.currentPage} />
              {currentOrg?.country?.toLowerCase() === 'russian federation' && <SupportButton />}
            </StyledContentPageLayout>
          </>
        )}
        {!validPaths.includes(props.currentPage) && (
          <StyledContent>
            <ErrorBoundary fromHeader={false}>{iff(!!props.content, props.content)}</ErrorBoundary>
            {currentOrg?.country?.toLowerCase() === 'russian federation' && <SupportButton />}
          </StyledContent>
        )}
      </Layout>
    </StyledLayout>
  );
};

PageLayout.propTypes = {
  header: PropTypes.node,
  content: PropTypes.node.isRequired,
  currentPage: PropTypes.string
};
