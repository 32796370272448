import { ConfigProvider } from 'antd';
import React, { Suspense, useEffect, useRef } from 'react';
import { Routes, Route, Navigate, useLocation, Outlet, useParams } from 'react-router-dom';
import { DndProvider } from 'react-dnd';
import '@geoman-io/leaflet-geoman-free';
import '@geoman-io/leaflet-geoman-free/dist/leaflet-geoman.css';
import 'leaflet.gridlayer.googlemutant';

import { TouchBackend } from 'react-dnd-touch-backend';
import { toThemeHoc } from '@styles/Theme';
import { StyledRoot } from '@styles/App.style';
import { SsoBranding } from './features/Sso/SsoBranding';
import { Loader } from './common/Loader/Loader';

import './index.less';
import { SsoConnector } from './features/Sso/SsoConnector';
import { getCurrentPage } from './utils/currentPageHelper';
import { lazyRetry } from './utils/retryLazyLoadingHelper';
import { PageLayout } from './Layout/page-layout';
import IpadWarningView from './common/IpadWarnningView';
import { isAccessTokenAvailable } from './utils/common-methods';
import { useLocale } from './hooks/useLocale';
import { mapInit } from './features/GoogleMap/LeafletMap/mapInit';

const AdminContainerComponent = React.lazy(() =>
  lazyRetry(() => import('./features/admin/components/Admin'))
);

const AssociateContainerComponent = React.lazy(() =>
  lazyRetry(() => import('./features/admin/components/Associate'))
);

const ProductCatalougeContainerComponent = React.lazy(() =>
  lazyRetry(() => import('./features/admin/components/ProductCatalouge'))
);

const LoginWrapper = React.lazy(() =>
  lazyRetry(() => import('./features/Sso/CropwiseOAuth2/LoginWrapper'))
);

const PageNotFound = React.lazy(() => lazyRetry(() => import('./common/PageNotFound')));
const LanguageDropdown = React.lazy(() =>
  lazyRetry(() => import('./features/localization/LanguageDropdown'))
);
const ManagerComponent = React.lazy(() => lazyRetry(() => import('./features/Manager')));

const DealerContainer = React.lazy(() => import('./features/Orgs'));
const DealerContainerComponent = () => {
  import('./features/Orgs');
};

const MultiFieldContainer = React.lazy(() => import('./features/MultiField'));
const MultiFieldContainerComponent = () => {
  import('./features/MultiField');
};

const SolutionsDashboard = React.lazy(() => import('./features/Solutions/List/SolutionsDashboard'));
const SolutionsDashboardComponent = () => {
  import('./features/Solutions/List/SolutionsDashboard');
};

const SolutionsDoubleReviewDashboard = React.lazy(() =>
  lazyRetry(() => import('./features/Solutions/Double-Review/dashboard'))
);
const SolutionReviewDashboard = React.lazy(() =>
  import('./features/Solutions/Review/SolutionReviewDashboard')
);
const SolutionReviewDashboardComponent = () => {
  import('./features/Solutions/Review/SolutionReviewDashboard');
};

const SpotCheckContainer = React.lazy(() => import('./features/SpotCheck'));
const SpotCheckContainerComponent = () => {
  import('./features/SpotCheck');
};

const PropertyList = React.lazy(() => import('./features/Properties'));
const PropertyLists = () => {
  import('./features/Properties'); // Pre-fetch the component
};

const PropertyContainer = React.lazy(() =>
  import('./features/Properties/landingFarm/LandingFarmContainer')
);
const PropertyContainerComponent = () => {
  import('./features/Properties/landingFarm/LandingFarmContainer');
};

const DrawPolygonComponent = React.lazy(() => import('./features/createField'));
const DrawPolygon = () => {
  import('./features/createField');
};

const FieldsCard = React.lazy(() => import('./features/createField/components/FieldsCard'));
const FieldsCardComponent = () => {
  import('./features/createField/components/FieldsCard');
};

const CreateNoBoundaryFieldForm = React.lazy(() =>
  lazyRetry(() => import('./features/createNoBoundaryField/components/CreateNoBoundaryFieldForm'))
);

const PropertyFields = React.lazy(() => import('./features/PropertyFields'));
const PropertyFieldsComponent = () => {
  import('./features/PropertyFields');
};

const UploadShapeFilesComponent = React.lazy(() =>
  import('./features/uploadFields/uploadShapeFiles')
);
const UploadShapeFiles = () => {
  import('./features/uploadFields/uploadShapeFiles');
};
const DetectFieldsComponent = React.lazy(() => import('./features/DetectFields/detectFields'));
const DetectFields = () => import('./features/DetectFields/detectFields');

const ShapeFilesListComponent = React.lazy(() => import('./features/uploadFields/shapeFilesList'));
const ShapeFilesList = () => {
  import('./features/uploadFields/shapeFilesList');
};

export const App = () => {
  const defaultOnErrorFn = useRef(window.onerror);
  const locale = useLocale();

  useEffect(() => {
    //let auth = isAccessTokenAvailable();
    //if (auth) {
    mapInit();
    //}
  }, []);

  function RequireAuth() {
    let auth = isAccessTokenAvailable();
    let location = useLocation();
    const params = useParams();

    let ua = navigator.userAgent;
    let checker = {
      iphone: ua.match(/(iPhone|iPod|iPad)/),
      android: ua.match(/Android/)
    };
    if (checker.iphone) {
      document.body.style.margin = '20px 0';
    } else if (
      navigator.maxTouchPoints &&
      navigator.maxTouchPoints > 2 &&
      navigator.platform === 'MacIntel'
    ) {
      document.body.style.margin = '40px 0px';
    }

    if (!auth) {
      return <Navigate to='/login' state={{ from: location }} replace />;
    }

    return PageLayout({
      content: <Outlet />,
      currentPage: getCurrentPage(location, params)
    });
  }
  useEffect(() => {
    PropertyLists();
    PropertyContainerComponent();
    SolutionsDashboardComponent();
    MultiFieldContainerComponent();
    SpotCheckContainerComponent();
    DealerContainerComponent();
    PropertyFieldsComponent();
    FieldsCardComponent();
    DrawPolygon();
    SolutionReviewDashboardComponent();
    UploadShapeFiles();
    ShapeFilesList();
    DetectFields();
  }, []);
  return (
    <StyledRoot>
      <SsoBranding />
      <div id='wrapper' style={{ height: '100%' }}>
        <Suspense fallback={<Loader />}>
          <DndProvider backend={TouchBackend} options={{ enableMouseEvents: true }}>
            <ConfigProvider locale={locale}>
              <Routes>
                <Route
                  path='/'
                  element={
                    <Suspense fallback={<Loader />}>
                      <LoginWrapper />
                    </Suspense>
                  }
                />
                <Route path='/sso/:exchangeToken' element={<SsoConnector />} />
                <Route
                  path='/login'
                  element={
                    <Suspense fallback={<Loader />}>
                      <LoginWrapper />
                    </Suspense>
                  }
                />

                <Route path='*' element={<PageNotFound />} />
                <Route path='app' element={<RequireAuth />}>
                  <Route path='*' element={<PageNotFound />} />
                  <Route
                    path='components/langdropdown'
                    element={
                      <Suspense fallback={<Loader />}>
                        <LanguageDropdown />
                      </Suspense>
                    }
                  />

                  <Route
                    path='manager'
                    element={
                      <Suspense fallback={<Loader />}>
                        <ManagerComponent />
                      </Suspense>
                    }
                  />
                  <Route
                    path='dealer'
                    element={
                      <Suspense fallback={<Loader />}>
                        <DealerContainer />
                      </Suspense>
                    }
                  />

                  <Route
                    path='solutions/:pathOrg/:pathSeason/:solutionId/:email_id?'
                    element={
                      <Suspense fallback={<Loader />}>
                        <SolutionReviewDashboard />
                      </Suspense>
                    }
                  />
                  <Route
                    path='solutions/:pathOrg/:pathSeason/:solutionId/:intersectsolutionid/double-review'
                    element={
                      <Suspense fallback={<Loader />}>
                        <SolutionsDoubleReviewDashboard />
                      </Suspense>
                    }
                  />
                  <Route
                    path='solutions/:pathOrg?/:pathSeason?'
                    exact={false}
                    element={
                      <Suspense fallback={<Loader />}>
                        <SolutionsDashboard />
                      </Suspense>
                    }
                  />
                  <Route
                    path='crop/:pathOrg?'
                    exact={false}
                    element={
                      <Suspense fallback={<Loader />}>
                        <MultiFieldContainer />
                      </Suspense>
                    }
                  />
                  <Route
                    path='spot-check/:pathSeason?/:pathOrg?/:solutionId?'
                    element={
                      <Suspense fallback={<Loader />}>
                        <SpotCheckContainer />
                      </Suspense>
                    }
                  />

                  <Route
                    path='landing-organization/:orgId/properties'
                    element={
                      <Suspense fallback={<Loader />}>
                        <PropertyList />
                      </Suspense>
                    }
                  />

                  <Route
                    path='landing-organization/source-id/:orgId/properties'
                    element={
                      <Suspense fallback={<Loader />}>
                        <PropertyList />
                      </Suspense>
                    }
                  />

                  <Route
                    path='landing-organization/:orgId/properties/create'
                    element={
                      <Suspense fallback={<Loader />}>
                        <PropertyContainer />
                      </Suspense>
                    }
                  />
                  <Route
                    path='landing-organization/:orgId/properties/:propertyId/edit'
                    element={
                      <Suspense fallback={<Loader />}>
                        <PropertyContainer />
                      </Suspense>
                    }
                  />
                  <Route
                    path='landing-organization/:id/property/:farmId/create-field/draw-field'
                    element={
                      <Suspense fallback={<Loader />}>
                        <DrawPolygonComponent />
                      </Suspense>
                    }
                  />
                  <Route
                    path='landing-organization/:id/property/:farmId/edit-field/:fieldId'
                    element={
                      <Suspense fallback={<Loader />}>
                        <DrawPolygonComponent />
                      </Suspense>
                    }
                  />
                  <Route
                    path='draw-polygon'
                    element={
                      <Suspense fallback={<Loader />}>
                        <DrawPolygonComponent />
                      </Suspense>
                    }
                  />
                  <Route
                    path='landing-organization/:id/property/:farmId/create-fields'
                    element={
                      <Suspense fallback={<Loader />}>
                        <FieldsCard />
                      </Suspense>
                    }
                  />
                  <Route
                    path='landing-organization/:id/property/:farmId/create-field/no-boundary'
                    element={
                      <Suspense fallback={<Loader />}>
                        <CreateNoBoundaryFieldForm />
                      </Suspense>
                    }
                  />
                  <Route
                    path='landing-organization/:id/property/:farmId/all-fields'
                    element={
                      <Suspense fallback={<Loader />}>
                        <PropertyFields />
                      </Suspense>
                    }
                  />

                  <Route
                    path='landing-organization/:id/create-farm'
                    element={
                      <Suspense fallback={<Loader />}>
                        <PropertyContainer />
                      </Suspense>
                    }
                  />
                  <Route
                    path='dealer/:id/create-farm'
                    element={
                      <Suspense fallback={<Loader />}>
                        <PropertyContainer />
                      </Suspense>
                    }
                  />
                  <Route
                    path='landing-organization/:id/edit-farm/:farmId'
                    element={
                      <Suspense fallback={<Loader />}>
                        <PropertyContainer />
                      </Suspense>
                    }
                  />
                  <Route
                    path='landing-organization/:id/property/:farmId/create-field/uploader'
                    element={
                      <Suspense fallback={<Loader />}>
                        <UploadShapeFilesComponent />
                      </Suspense>
                    }
                  />
                  <Route
                    path='landing-organization/:id/property/:farmId/create-field/detect-fields'
                    element={
                      <Suspense fallback={<Loader />}>
                        <DetectFieldsComponent />
                      </Suspense>
                    }
                  />
                  <Route
                    path='landing-organization/:id/property/:farmId/create-field/uploaded-list'
                    element={
                      <Suspense fallback={<Loader />}>
                        <ShapeFilesListComponent />
                      </Suspense>
                    }
                  />
                </Route>
                <Route path='support' element={<RequireAuth />}>
                  <Route
                    path='admin'
                    element={
                      <Suspense fallback={<Loader />}>
                        <AdminContainerComponent />
                      </Suspense>
                    }
                  />
                  <Route
                    path='associate'
                    element={
                      <Suspense fallback={<Loader />}>
                        <AssociateContainerComponent />
                      </Suspense>
                    }
                  />
                  <Route
                    path='productcatalouge'
                    element={
                      <Suspense fallback={<Loader />}>
                        <ProductCatalougeContainerComponent />
                      </Suspense>
                    }
                  />
                </Route>
              </Routes>
            </ConfigProvider>
          </DndProvider>
        </Suspense>
      </div>
      <IpadWarningView />
    </StyledRoot>
  );
};

export const AppLazy = toThemeHoc(App, 'defaultTheme');
