import React from 'react';
import PropTypes from 'prop-types';
import { DeckOfApps } from '@cw-elements/deck-of-apps';
import styled from 'styled-components';

const DeckOfAppsStyle = styled.div`
  margin: 10px 0px;
`;
export const DeckApp = ({ selectedOrg }) => {
  return (
    <DeckOfAppsStyle>
      <DeckOfApps workspaceId={selectedOrg?.id} orgId={selectedOrg?.id} />
    </DeckOfAppsStyle>
  );
};

DeckApp.propTypes = {
  selectedOrg: PropTypes.any
};
