import styled from 'styled-components';
import { Content } from 'antd/lib/layout/layout'; // Adjust path as necessary
export const StyledContentPageLayout = styled(Content)`
  flex: 1;
  padding: 0;
  height: ${(props) => `calc(${props.windowHeight}px - 65px)`};

  /* Styles for Home Page */
  ${(props) =>
    props.currentPage === 'propertyPage' &&
    `
     .leaflet-container{
     height: calc(100vh - 60px);
   }
   /** leaflet override css */
	.leaflet-popup-content{
		margin: 0;
	}
	#popup{
    padding: 13px 10px;
      font-family: Rubik;
      font-style: normal;
      font-weight: 500;
      font-size: 24px;
      line-height: 28px;
      color: #2f3031;
  }
	.leaflet-popup-content-wrapper {
    width: 375px;
    height: 77px;
}
  `}
  ${(props) =>
    props.currentPage === 'spotCheckPage' &&
    `
   .leaflet-container{
     height: calc(100vh - 60px);
   }
  .leaflet-popup {
    width: 36em;
    bottom: -7px !important;
    font-family: Rubik, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue',
      Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
      'Noto Color Emoji';
  }

  .leaflet-popup-content-wrapper {
    border-radius: 12px;
  }
  .leaflet-popup-content {
    margin: 0px;
    width: 100% !important;
  }
  .leaflet-container a.leaflet-popup-close-button {
    margin-right: -5px;
  }
  //// IPad 8/11 tested
  @media only screen and (max-width: 1195px) and (min-height: 650px) and (max-height: 899px) and (orientation: landscape) {
    .leaflet-popup {
      width: 36em;
      //border-top: 5px solid red;
    }
    .leaflet-popup-content {
      margin: 0px;
      width: 100% !important;
    }
    .leaflet-container a.leaflet-popup-close-button {
      margin-right: -5px;
    }
  }
  // IPad 12 tested
  @media only screen and (min-height: 900px) and (max-height: 910px) and (orientation: landscape) {
    .leaflet-popup {
      width: 36em;
      //border-top: 5px solid blue;
    }
    .leaflet-popup-content {
      margin: 0px;
      width: 100% !important;
    }
    .leaflet-container a.leaflet-popup-close-button {
      margin-right: -5px;
    }
  }
  `}

  ${(props) =>
    props.currentPage === 'cropPage' &&
    `
  .leaflet-container{
     height: calc(100vh - 60px);
   }
  .leaflet-popup {
    width: 36em;
    font-family: Rubik, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue',
      Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
      'Noto Color Emoji';
  }
  .leaflet-popup-content-wrapper {
    border-radius: 12px;
  }
  .leaflet-popup-content {
    margin: 0px;
    width: 100% !important;
  }
  .leaflet-container a.leaflet-popup-close-button {
    margin-right: -5px;
  }
  //// IPad 8/11 tested
  @media only screen and (max-width: 1195px) and (min-height: 650px) and (max-height: 899px) and (orientation: landscape) {
    .leaflet-popup {
      width: 36em;
      //border-top: 5px solid red;
    }
    .leaflet-popup-content {
      margin: 0px;
      width: 100% !important;
    }
    .leaflet-container a.leaflet-popup-close-button {
      margin-right: -5px;
    }
  }
  // IPad 12 tested
  @media only screen and (min-height: 900px) and (max-height: 910px) and (orientation: landscape) {
    .leaflet-popup {
      width: 36em;
      //border-top: 5px solid blue;
    }
    .leaflet-popup-content {
      margin: 0px;
      width: 100% !important;
    }
    .leaflet-container a.leaflet-popup-close-button {
      margin-right: -5px;
    }
  }
  `}
  /* Styles for Dealer Page */
  ${(props) =>
    props.currentPage === 'dealer' &&
    `
     .leaflet-container{
     height: calc(100vh - 60px);
   }
    .leaflet-popup-pane {
      margin-top: -50px !important;
    }
    .mycluster {
      transform: translate3d(415px, 168px, 0px);
      z-index: 168;
      opacity: 1;
      line-height: 50px;
      width: 42px !important;
      height: -webkit-fill-available;
    }
  /** leaflet override css */
  .leaflet-popup-content {
    margin: 0;
  }
   .leaflet-popup {
    margin-bottom: -20px !important;
  }
  #popup {
    padding: 13px 10px;
    font-family: Rubik;
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 28px;
    color: #2f3031;
  }
  .leaflet-popup-content-wrapper {
    width: 380px;
    height: 77px;
  }
    /* Add any additional classes here as needed */
  `}
`;
