import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Tooltip, Badge, Select } from 'antd';
import { getSelectedOrganizationCountryDetails } from '@selectors/headerSelectors';
import { iff } from '@utils/iff';
import { getUserDetails } from '@selectors/userProfileSelector';
import { validateWorkspace } from '@actions';
import { history, URL_CONSTANTS } from '@utils/history';
import { errorNotification } from '../../common/alertMessages';
import { CustomSelect } from '../../common/CustomSelect/CustomSelect';
import { missingOrgAmplitude } from '../../utils/validateUser';

import { DropdownDefaultTitleDiv, DropdownDefaultTitle, DropdownDefaultPlacholder } from './styles';
import { AvatarComponent } from '../../common/Avatar/Avatar';
import { CustomDropdown } from './commonComponents';

export const OrgSelect = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const currentOrg = useSelector(getSelectedOrganizationCountryDetails);
  const currentUser = useSelector((state) => getUserDetails(state));

  const domElement = {};
  const { Option } = Select;
  const {
    isRecommendationLoad,
    isAllFieldLoaded,
    filteredOrgs,
    currentPage,
    setCurrentOrg,
    setCurrentOrgObject,
    setSourceIdForOrg,
    sourceIDList
  } = props;
  const redirectToProperties = useCallback(
    (orgId) => {
      if (
        currentPage !== 'cropPage' &&
        currentPage !== 'managementZonesPage' &&
        currentPage !== 'plantingPage' &&
        currentPage !== 'solutions' &&
        currentPage !== 'solution_review' &&
        currentPage !== 'spotCheckPage'
      ) {
        history.push(URL_CONSTANTS.PROPERTIES({ orgId: orgId }));
      }
    },
    [currentPage]
  );

  const onOrgChange = useCallback(
    async (val, options) => {
      let select = document.querySelector(
        '[data-testid=collapsedHeader-select-organization] .ant-select-selection-search-input'
      );
      select.blur();
      if (val !== 'default') {
        let orgObj = filteredOrgs.find((item) => item.id === val);
        if (
          window.location.host.includes('seedselector') ||
          window.location.host.includes('localhost') ||
          window.location.host.includes('192.168')
        ) {
          if (await validateWorkspace(dispatch, orgObj)) {
            await setCurrentOrg(options.key);
            redirectToProperties(options.key);
            let sourceIdForOrg;
            // eslint-disable-next-line array-callback-return
            sourceIDList.map((i) => {
              if (i.orgId === options.key) {
                sourceIdForOrg = i.sourceId;
              }
            });
            setSourceIdForOrg(sourceIdForOrg);
          } else {
            missingOrgAmplitude(orgObj, currentUser);
            errorNotification(
              t('Error'),
              t('This org doesnt have Seed Selector Contract'),
              t('Dismiss')
            );
            console.error('This org is not in Seed selector plan');
          }
        } else {
          await setCurrentOrg(options.key);
          redirectToProperties(options.key);
          let sourceIdForOrg;
          // eslint-disable-next-line array-callback-return
          sourceIDList.map((i) => {
            if (i.orgId === options.key) {
              sourceIdForOrg = i.sourceId;
            }
          });
          setSourceIdForOrg(sourceIdForOrg);
        }
      }
    },
    [
      dispatch,
      filteredOrgs,
      redirectToProperties,
      setCurrentOrg,
      setCurrentOrgObject,
      setSourceIdForOrg,
      sourceIDList
    ]
  );

  return (
    <CustomSelect
      data-testid='collapsedHeader-select-organization'
      disabled={isRecommendationLoad || isAllFieldLoaded}
      showSearch
      optionFilterProp='children'
      filterOption={(input, option) => {
        return option.props.name.toLowerCase().indexOf(input.toLowerCase()) >= 0;
      }}
      size='large'
      className='orgSelect'
      onFocus={() => {
        const orgElement = document.querySelector(
          '[data-testid="collapsedHeader-select-organization"]'
        );
        const selectedElement = orgElement.querySelector('.ant-select-selection-item');
        domElement.orgDropDown = selectedElement.cloneNode(true);
        domElement.orgDropDown.id = 'cloned_org_div';
      }}
      onInputKeyDown={() => {
        const selectItem = document.getElementById('cloned_org_div');
        if (!selectItem) {
          document
            .querySelector('[data-testid="collapsedHeader-select-organization"]')
            .querySelector('[class="ant-select-selector"]')
            .appendChild(domElement.orgDropDown);
        }
      }}
      onBlur={() => {
        const selectItem = document.getElementById('cloned_org_div');
        if (selectItem) {
          selectItem.remove();
        }
      }}
      onSelect={onOrgChange}
      bordered={false}
      margintop={0}
      value={
        currentOrg.name ? (
          <Option
            className='orgSelectOption'
            key={currentOrg.id}
            value={currentOrg.id}
            name={currentOrg.name}
          >
            <div style={{ display: 'flex', padding: '10px 0' }}>
              <Badge>
                <AvatarComponent
                  alt={t('Organization Image')}
                  fontIconName='company'
                  fontIconSize='28'
                  fontIconColor='#198746'
                  color='#FFFFFF'
                />
              </Badge>
              <DropdownDefaultTitleDiv style={{ padding: '0 10px' }}>
                <DropdownDefaultTitle>{t('Organization')}</DropdownDefaultTitle>
                <DropdownDefaultPlacholder>
                  <Tooltip title={currentOrg.name}>
                    <span>{currentOrg.name}</span>
                  </Tooltip>
                </DropdownDefaultPlacholder>
              </DropdownDefaultTitleDiv>
            </div>
          </Option>
        ) : (
          'default'
        )
      }
      dropdownStyle={{ borderRadius: 8, left: 90, top: 60 }}
      style={{ width: 260, margin: '0 0.5em' }}
      dropdownRender={(menu) => <CustomDropdown menu={menu} t={t} label={'My organizations'} />}
    >
      {!currentOrg.name && (
        <Option hidden key='default' value='default' name='default'>
          <div style={{ display: 'flex', padding: '10px 0' }}>
            <AvatarComponent
              style={{ height: 55, width: 40 }}
              src={''}
              alt={t('Organization Image')}
              fontIconName='company'
              fontIconSize='28'
              fontIconColor='#198746'
              fontIconStyle={{ lineHeight: '40px' }}
              color='#FFFFFF'
            />
            <DropdownDefaultTitleDiv>
              <DropdownDefaultTitle>{t('Organization')}</DropdownDefaultTitle>
              <DropdownDefaultPlacholder>
                <span>{t('Please Select Organization')}</span>
              </DropdownDefaultPlacholder>
            </DropdownDefaultTitleDiv>
          </div>
        </Option>
      )}
      {filteredOrgs.map((item) => (
        <Option className='orgSelectOption' key={item.id} value={item.id} name={item.name}>
          <div style={{ display: 'flex', padding: '10px 0' }}>
            <Badge status={iff(item.meta && item.meta.isFav === true, 'warning', '')}>
              <AvatarComponent
                src={item?.picture_url}
                alt={t('Organization Image')}
                fontIconName='company'
                fontIconSize='28'
                fontIconColor='#198746'
                color='#FFFFFF'
              />
            </Badge>
            <DropdownDefaultTitleDiv style={{ padding: '0 10px' }}>
              <DropdownDefaultTitle className='orgtitle'>{t('Organization')}</DropdownDefaultTitle>
              <DropdownDefaultPlacholder
                id={`org-select-${item.id}`}
                style={{
                  width: '205px'
                }}
                className='selectedOrg'
                title={item.name}
              >
                {item.name}
              </DropdownDefaultPlacholder>
            </DropdownDefaultTitleDiv>
          </div>
        </Option>
      ))}
    </CustomSelect>
  );
};

OrgSelect.propTypes = {
  isRecommendationLoad: PropTypes.any,
  isAllFieldLoaded: PropTypes.any,
  filteredOrgs: PropTypes.any,
  currentPage: PropTypes.any,
  setCurrentOrg: PropTypes.any,
  setCurrentOrgObject: PropTypes.any,
  setSourceIdForOrg: PropTypes.any,
  sourceIDList: PropTypes.any
};

OrgSelect.propTypes = {
  isRecommendationLoad: PropTypes.any,
  isAllFieldLoaded: PropTypes.any,
  filteredOrgs: PropTypes.any,
  currentPage: PropTypes.any,
  setCurrentOrg: PropTypes.any,
  setCurrentOrgObject: PropTypes.any,
  setSourceIdForOrg: PropTypes.any,
  sourceIDList: PropTypes.any
};
