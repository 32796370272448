export const getCurrentPage = (location, params, type = 'page') => {
  const { pathname } = location;
  const validPaths = {
    '/app/spot-check': 'spotCheckPage',
    '/app/crop': 'cropPage',
    '/app/solutions': 'solutions',
    '/app/dealer': 'dealer',
    '/support/productcatalouge': 'product-catalouge',
    '/support/admin': 'admin',
    '/support/associate': 'associate',
    '/app/manager': 'manager'
  };

  let currentPage = '';
  Object.keys(validPaths).forEach((item) => {
    if (pathname && pathname.includes(item)) {
      if (type === 'path') {
        currentPage = item;
      } else {
        currentPage = validPaths[item];
      }
    }
  });

  const regex = /^\/app\/landing-organization\/[^/]+\/properties$/;
  if (regex.test(pathname)) {
    currentPage = 'propertyPage';
  }
  return currentPage;
};
