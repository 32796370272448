import React, { useRef, useState, useEffect } from 'react';
import GoogleLayer from '../features/GoogleMap/GoogleLayer';
import { useMapContext } from '../features/GoogleMap/MapProvider';
import { MapContainer, FeatureGroup } from 'react-leaflet';
import { selectedFieldIdsUpdate } from '../features/GoogleMap/helpers/selectedFieldIdsUpdate';
import { validateFieldsGeometry } from '../features/GoogleMap/helpers/vaidateFieldsGeometry';
import { forceUpdateMap } from '../features/GoogleMap/helpers/forceUpdateMap';
import { updateMarkerAndFields } from '../features/GoogleMap/helpers/updateMarkerAndFields';
import { updateCurrentZoom } from '../features/GoogleMap/helpers/updateCurrentZoom';
import Markers from '../features/GoogleMap/Markers';
import { MapFieldsLayer } from '../features/GoogleMap/MapFieldsLayer';
import { MapEvents } from '../features/GoogleMap/MapEvents';
import { MapStyle } from '../features/GoogleMap/styles';
import { MapAdditionalServices } from '../features/MapAdditionalServices';
import { mapBounds } from '../features/GoogleMap/helpers/mapBounds';
export const MapLayout = ({ currentPage }) => {
  const { setCurrentMapInstance, currentProps, mapCurrentInstance } = useMapContext();
  const mapRef = useRef(null);
  const groupRef = useRef(null);
  useEffect(() => {
    if (mapRef.current) {
      setCurrentMapInstance(mapRef.current);
    }
  }, [setCurrentMapInstance, mapRef.current, currentProps]);

  const {
    isMarkers,
    fields,
    coords,
    isFields,
    setMapInstance = () => {},
    onClick,
    mapId = 'map-id',
    isReloadMap,
    isMultiFieldPage,
    selectedFieldIds,
    mapMarkers = [],
    canPopupOpen,
    // eslint-disable-next-line no-empty-function
    onMapClick = () => {},
    isSpotCheckPage,
    isDoubleReviewPage,
    isFieldMetaMap,
    isAllFieldList
  } = currentProps;
  const mapCenter = coords && coords.length > 0 ? coords : null;
  if (localStorage.getItem('DEBUG')) {
    // eslint-disable-next-line no-console
    console.log({ currentProps }, ' currentProp map =====>');
  }
  const [mapFields, setMapFields] = useState([]);
  const [mapState, setMapState] = useState({
    currentIndex: 0,
    totalIndex: 2
  });
  const [mapFieldIds, setMapFieldIds] = useState([]);
  const [isUpdate, setIsUpdate] = useState(true);
  const [currentZoom, setCurrentZoom] = useState(0);
  const [isMarkerUpdate, setIsMarkerUpdate] = useState(true);
  useEffect(() => {
    selectedFieldIdsUpdate({ setIsMarkerUpdate, selectedFieldIds, mapRef });
  }, [JSON.stringify(selectedFieldIds)]);

  useEffect(() => {
    validateFieldsGeometry({
      setMapFields,
      fields,
      setIsUpdate,
      mapRef,
      setMapState,
      isFields,
      mapState
    });
  }, [JSON.stringify(fields)]);

  useEffect(() => {
    forceUpdateMap();
  }, [isReloadMap]);

  useEffect(() => {
    return function cleanup() {
      setIsMarkerUpdate(true);
    };
  }, [currentPage]);
  useEffect(() => {
    updateMarkerAndFields({
      mapMarkers,
      mapFields,
      mapRef,
      groupRef,
      isSpotCheckPage,
      isMultiFieldPage,
      fields,
      setMapInstance,
      mapFieldIds,
      setMapFieldIds,
      setMapFields,
      isFieldMetaMap,
      isAllFieldList
    });
  }, [mapMarkers, JSON.stringify(mapFields), JSON.stringify(fields), mapFieldIds, mapRef]);
  useEffect(() => {
    if (typeof setMapInstance === 'function') {
      setTimeout(() => {
        setMapInstance(mapRef.current);
      }, 1000);
    }
  }, []);
  useEffect(() => {
    if (isMultiFieldPage && !canPopupOpen && mapRef && mapRef.current) {
      mapRef.current.closePopup();
    }
  }, [canPopupOpen]);
  const onChangeZoom = (zoom) => {
    updateCurrentZoom({
      mapRef,
      setCurrentZoom,
      isMultiFieldPage,
      setIsMarkerUpdate,
      zoom,
      isSpotCheckPage,
      isAllFieldList
    });
  };
  const { extraBounds } = mapBounds(mapMarkers);
  const { center } = mapState;
  let extraCenter = {};
  if (center) {
    extraCenter = {
      center
    };
  }
  if (mapCenter && !center) {
    extraCenter = {
      center: mapCenter
    };
  }
  return (
    <MapStyle className={mapId.toLowerCase()}>
      <MapContainer
        ref={mapRef}
        id={mapId}
        center={[49.48242187500001, 41.376808565702355]}
        zoom={10}
        maxZoom={20}
        style={{ height: `100%` }}
      >
        <GoogleLayer />
        <FeatureGroup ref={groupRef}>
          {isMarkers &&
          isMarkerUpdate &&
          (!isMultiFieldPage || (isMultiFieldPage && currentZoom < 12)) ? (
            <Markers {...currentProps} selectedFieldIds={selectedFieldIds} />
          ) : null}
          {isFields ? (
            <MapFieldsLayer
              mapRef={mapRef}
              mapFields={mapFields}
              mapFieldIds={mapFieldIds}
              update={isUpdate}
              mapState={mapState}
              setMapState={setMapState}
              onClick={onClick}
              canPopupOpen={canPopupOpen}
              isDoubleReviewPage={isDoubleReviewPage}
              selectedFieldIds={selectedFieldIds}
              {...currentProps}
            />
          ) : null}
        </FeatureGroup>
        <MapEvents
          center={extraCenter}
          extraBounds={extraBounds}
          updateCurrentZoom={onChangeZoom}
          onMapClick={onMapClick}
        />
        {currentProps.sessionData && currentProps.isMultiFieldPage && (
          <MapAdditionalServices
            logAmplitudeEvent={currentProps.logAmplitudeEvent}
            propertyId={currentProps.propertyId}
            propertyCount={currentProps.propertyCount}
            propertyFieldsCount={currentProps.PropertyFieldsCount}
            fields={currentProps.sessionData ? currentProps.sessionData.regionFields : []}
            mapInstance={mapCurrentInstance}
            isFieldUpdate={currentProps.isFieldUpdate}
            topPosition='2px'
          />
        )}
      </MapContainer>
    </MapStyle>
  );
};
