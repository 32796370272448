import PropTypes from 'prop-types';
import React from 'react';
import * as Sentry from '@sentry/browser';

import { connect } from 'react-redux';
import styled from 'styled-components';
import { currentFeatureSet, isEnabled } from '@selectors/featureSet';
import { isDealer, isUserLoading } from '@selectors/userProfileSelector';
import { URL_CONSTANTS } from '@utils/history';
import { iff } from '@utils/iff';
import { eameWebEndpoints } from '@utils/urlUtil';
import { isLogoutInitiated } from '@utils/common-methods';
import { Loader } from './Loader/Loader';

const StyledContent = styled.section`
  text-align: center;
  padding: 100px;
`;

class ErrorBoundaryComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // eslint-disable-next-line no-console
    console.log(error, errorInfo);
    if (eameWebEndpoints.includes(window.location.host)) {
      Sentry.withScope((scope) => {
        scope.setExtras(errorInfo);
        const eventId = Sentry.captureException(error);
        this.setState({ eventId, errorInfo });
      });
    }
  }

  render() {
    const isErrorThrownBeforeLogout = this.state.hasError && !isLogoutInitiated();
    if (isErrorThrownBeforeLogout) {
      const manageOrganization = isEnabled('ManageOrganization', this.props.featureSet);
      return (
        <>
          {iff(
            this.props.loading,
            <Loader />,
            iff(
              !this.props.fromHeader,
              <StyledContent>
                <h2>Oops! Something went wrong.</h2>
                <span>
                  Hang tight - we&lsquo;ve notified the team.
                  <br />
                  Please{' '}
                  <a
                    href={iff(
                      this.props.isDealer,
                      () => (manageOrganization ? URL_CONSTANTS.DEALER() : URL_CONSTANTS.CROP()),
                      () => URL_CONSTANTS.MANAGER()
                    )}
                  >
                    click here
                  </a>{' '}
                  to visit Dashboard.
                </span>
              </StyledContent>,
              <div className='header-error-boundary'>Oops! Something went wrong.</div>
            )
          )}
        </>
      );
    }
    return this.props.children;
  }
}

ErrorBoundaryComponent.propTypes = {
  children: PropTypes.node,
  isDealer: PropTypes.bool,
  fromHeader: PropTypes.bool,
  loading: PropTypes.bool,
  featureSet: PropTypes.any
};

const mapStateToProps = (state) => {
  return {
    featureSet: currentFeatureSet(state),
    loading: isUserLoading(state),
    isDealer: isDealer(state)
  };
};

export const ErrorBoundary = connect(mapStateToProps, null)(ErrorBoundaryComponent);
