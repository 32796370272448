import i18n from 'i18next';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { currentBranding } from '@selectors/branding';

/**
 * A component that takes branding from redux and applies it to the page
 */
export const SsoBranding = () => {
  const { t } = useTranslation();
  const branding = useSelector(currentBranding);

  // Set the page title
  useEffect(() => {
    if (!branding.pageTitle) {
      return;
    }

    document.title = t(branding.pageTitle);
  }, [t(branding.pageTitle ?? '')]);

  // Set the favicon for the page
  useEffect(() => {
    if (!branding.favIcon) {
      return;
    }

    const favIconRef = document.getElementById('favicon') as HTMLLinkElement | null;
    if (!favIconRef) {
      return;
    }

    favIconRef.href = branding.favIcon;
  }, [branding.favIcon]);

  // Set any css file link
  useEffect(() => {
    if (!branding.styleOverrides) {
      return;
    }

    document
      .getElementsByTagName('head')[0]
      .insertAdjacentHTML(
        'beforeend',
        `<link rel="stylesheet" href="${branding.styleOverrides}" />`
      );
  }, [branding.styleOverrides]);

  // Set default language if there is one
  // In particular, this is used for the SSO logins that send a default language
  useEffect(() => {
    // only set the language if there is not a language already saved to local storage
    // this ensures that if the user has explicitly selected a different language, their choice is maintained
    if (branding.defaultLanguage && !localStorage.getItem('LangParams')) {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      i18n.changeLanguage(branding.defaultLanguage);
    }
  }, [branding.defaultLanguage]);

  return <></>;
};
