import { Divider } from 'antd';
import { UpCircleFilled } from '@ant-design/icons';
import get from 'lodash/get';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect } from 'react';
import { connect, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import {
  setSelectedSeasons,
  setCurrentOrg,
  setCurrentOrgObject,
  setCurrentReseller,
  setSourceIdForOrg,
  setCurrentProp
} from '@actions';
import { setLatLongorgReset, fetchDealerList, setOrgsWithSourceId } from '@actions/orgsList';
import { fetchUserProfile, logoutUser } from '@actions/user';
import { currentBranding } from '@selectors/branding';
import { getSelectedOrganization, getSelectedReseller } from '@selectors/headerSelectors';
import { getSelectedOrganizationCountry } from '@selectors/orgConfigSelectors';
import { CollapsedHeader } from './collapsedHeader';
import { isFavouriteByOrgId } from '@reducer/FavouriteEntity/favouriteOrgListSelector';
import {
  getallOrgsList,
  getSourceIDList,
  getOrgsListForDashboard
} from '@selectors/organizationListSelector';
import { getPropertiesByOrgId } from '@reducer/Properties/selector';
import { isManager, getUserEmail, isAdmin } from '@selectors/userProfileSelector';
import {
  isAccessTokenAvailable,
  clearLocalStorage,
  clearSessionStorage
} from '@utils/common-methods';
import { URL_CONSTANTS } from '@utils/history';
import { iff } from '@utils/iff';
import {
  isCropwiseOAuth2Login,
  logoutSSOUserFromApp,
  trackSSOLogoutOperation
} from '../../features/Sso/CropwiseOAuth2';
import { getAllResellersList } from '@reducer/Manager/selector';

const StyledDiv = styled.div`
  position: absolute;
  top: 181px;
  z-index: 2000;
`;

const HeaderContainer = (props) => {
  const { getUserProfile, userProfile } = props;
  const { farmId } = useParams();

  const branding = useSelector(currentBranding);

  const handleLogout = useCallback(() => {
    clearLocalStorage();
    clearSessionStorage();
    trackSSOLogoutOperation();
    logoutSSOUserFromApp();
    props.logoutUser();
    window.zE('webWidget', 'hide');
  }, [branding, props]);

  useEffect(() => {
    if (userProfile && !userProfile.role) {
      getUserProfile();
    }

    if (!isAccessTokenAvailable()) {
      props.history.push(URL_CONSTANTS.LOGIN());
    }

    // leave this dependency list empty for this effect. when the dependencies are listed, this effect will be continuously called for users without an assigned role
  }, []);

  return (
    <>
      <CollapsedHeader
        orgs={props.orgListForDashboard || []}
        defaultOrgs={props.orgs}
        resellersList={props.sales.length > 0 ? props.sales : []}
        properties={props.properties}
        propertyId={farmId}
        userProfile={props.userProfile}
        setCurrentOrg={props.setCurrentOrg}
        setCurrentOrgObject={props.setCurrentOrgObject}
        setCurrentProp={props.setCurrentProp}
        setCurrentReseller={props.setCurrentReseller}
        handleLogout={handleLogout}
        currentOrg={props.currentOrg}
        currentReseller={props.currentReseller}
        isRoleManager={props.isRoleManager}
        isRoleAdmin={props.isRoleAdmin}
        currentPage={props.currentPage}
        setOrgListForDashboard={props.setOrgListForDashboard}
        setLatLongorgReset={props.setLatLongorgReset}
        sourceIDList={props.sourceIDList}
        setSourceIdForOrg={props.setSourceIdForOrg}
        isAllFieldLoaded={props.isAllFieldLoaded}
        setOrganizationsWithSourceId={props.setOrganizationsWithSourceId}
      />
      {iff(!props.isCollapsed, () => (
        <StyledDiv>
          <Divider style={{ margin: 0 }}>
            <UpCircleFilled style={{ fontSize: '30px', color: '#00A0BE' }} />
          </Divider>
        </StyledDiv>
      ))}
    </>
  );
};

HeaderContainer.propTypes = {
  orgs: PropTypes.array.isRequired,
  properties: PropTypes.array.isRequired,
  //orgId: PropTypes.string,
  //propertyId: PropTypes.string,
  isCollapsed: PropTypes.bool.isRequired,
  //header: PropTypes.node,
  history: PropTypes.object,
  getUserProfile: PropTypes.func.isRequired,
  userProfile: PropTypes.object.isRequired,
  //setSelectedSeasons: PropTypes.func,
  setCurrentOrg: PropTypes.func,
  setCurrentOrgObject: PropTypes.func,
  setCurrentProp: PropTypes.func,
  setCurrentReseller: PropTypes.func,
  orgListForDashboard: PropTypes.array,
  currentPage: PropTypes.string,
  sourceIDList: PropTypes.array,
  setSourceIdForOrg: PropTypes.func,
  logoutUser: PropTypes.any,
  sales: PropTypes.any,
  currentOrg: PropTypes.any,
  currentReseller: PropTypes.any,
  isRoleManager: PropTypes.any,
  isRoleAdmin: PropTypes.any,
  setOrgListForDashboard: PropTypes.any,
  setLatLongorgReset: PropTypes.any,
  isAllFieldLoaded: PropTypes.any,
  setOrganizationsWithSourceId: PropTypes.any
};

const mapStateToProps = (state, ownProps) => {
  const userEmail = getUserEmail(state);
  const orgId = getSelectedOrganization(state);
  const orgs = getallOrgsList(state);
  const sales = getAllResellersList(state);
  const properties = getPropertiesByOrgId(state, { orgId: orgId });
  const propertyId = get(ownProps, 'match.params.farmId', null);
  const isRoleManager = isManager(state);
  const isRoleAdmin = isAdmin(state);

  const listToFilter = getOrgsListForDashboard(state);

  /**
   * Concat FavOrgList and AllOrgList by sorting in desc order.
   */
  const favList = listToFilter.filter((org) =>
    isFavouriteByOrgId(state, { orgId: org.id, userEmail })
  );
  const allList = listToFilter.filter(
    (org) => !isFavouriteByOrgId(state, { orgId: org.id, userEmail })
  );

  const favListAddMeta = favList.map((org) => {
    return {
      ...org,
      meta: {
        isFav: true
      }
    };
  });

  return {
    orgs,
    properties,
    orgId,
    propertyId: propertyId,
    userProfile: state.user.userProfile,
    sales: sales,
    currentOrg: orgs.find((o) => o.id === orgId) || {},
    currentReseller: getSelectedReseller(state),
    orgListForDashboard: favListAddMeta.concat(allList),
    allFields: state.allFields.data,
    isAllFieldLoaded: state.allFields.isAllFieldLoaded,
    sourceIDList: getSourceIDList(state),
    isRoleManager,
    isRoleAdmin,
    orgCountry: getSelectedOrganizationCountry(state)
  };
};

const mapDispatchToProps = (dispatch) => ({
  setSelectedSeasons: (seasons) => dispatch(setSelectedSeasons(seasons)),
  getUserProfile: () => dispatch(fetchUserProfile()),
  setCurrentOrg: (orgId) => dispatch(setCurrentOrg(orgId)),
  setCurrentOrgObject: (orgId) => dispatch(setCurrentOrgObject(orgId)),
  setCurrentProp: (propertyId) => dispatch(setCurrentProp(propertyId)),
  setCurrentReseller: (resId, active) => dispatch(setCurrentReseller(resId, active)),
  setLatLongorgReset: () => dispatch(setLatLongorgReset()),
  setSourceIdForOrg: (sourceIdForOrg) => dispatch(setSourceIdForOrg(sourceIdForOrg)),
  fetchDealerList: (userInfo) => dispatch(fetchDealerList(userInfo)),
  setOrganizationsWithSourceId: (sourceIdList) => dispatch(setOrgsWithSourceId(sourceIdList)),
  logoutUser: () => dispatch(logoutUser())
});

export const HeaderContainerComponent = connect(
  mapStateToProps,
  mapDispatchToProps
)(HeaderContainer);
