/* eslint-disable import/imports-first */
import './styles/icomoon-v1.0/style.css';
import React from 'react';
import { createRoot } from 'react-dom/client';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import amplitude from 'amplitude-js';
import { initCWElements } from '@cw-elements/config';
import { setAutoFreeze, enablePatches } from 'immer';
import ResizeObserver from 'resize-observer-polyfill';
import { Provider } from 'react-redux';
import { store } from './reducer/store';
import { AppLoader } from './AppLoader';
import { setNotificationClient } from './services/Notifications/notificationClient';
import { eameWebEndpoints } from './utils/urlUtil';
import { history } from './utils/history';
import { AppLazy } from './app';
import { BrowserRouter } from './routes/BrowserRouter';
import { getUserLocationDetails } from '@utils/ipApi';
import { MapProvider } from './features/GoogleMap/MapProvider';
if (eameWebEndpoints.includes(window.location.host)) {
  setTimeout(() => {
    Sentry.init({
      dsn: process.env.SENTRY_DSN,
      integrations: [new Integrations.BrowserTracing()],
      tracesSampleRate: 0.5,
      ignoreErrors: [],
      environment: process.env.envName,
      release: `${process.env.envName} -> ${process.env.npm_package_version}`
    });
  }, 10000);
}
if (process.env.AMPLITITUDE_KEY) {
  amplitude.getInstance().init(process.env.AMPLITITUDE_KEY);
}
if (!window.ResizeObserver) {
  window.ResizeObserver = ResizeObserver;
}
// for Deck app
initCWElements({
  environment: process.env.ENV_NAME,
  token: false,
  axiosInstance: false,
  currentAppId: process.env.APP_ID
});

// Load zendesk script.
setTimeout(() => {
  const script = document.createElement('script');
  script.src = `https://static.zdassets.com/ekr/snippet.js?key=${process.env.ZENDESK_KEY}`;
  script.async = true;
  script.id = 'ze-snippet';
  document.body.appendChild(script);
  script.onload = () => {
    // eslint-disable-next-line no-undef
    zE('webWidget', 'hide');
  };
}, 5000);
setNotificationClient();

//Remove smartlook script if the users logged in country is Russia
getUserLocationDetails()
  .then((locationData) => {
    if (locationData.country_code == 'RU') {
      let scripts = document.getElementsByTagName('script');
      for (let i = scripts.length - 1; i >= 0; i--) {
        if (scripts[i].src && scripts[i].src.startsWith('https://web-sdk.smartlook.com')) {
          scripts[i].parentElement.removeChild(scripts[i]);
        }
      }
    }
  })
  .catch((error) => {
    console.warn('Error fetching location data:');
  });

const container = document.getElementById('root');
const root = createRoot(container); // createRoot(container!) if you use TypeScript
const appState = new AppLoader(store);
appState.initI18n();
appState.redirectAsPerLoggedInStatus();
setAutoFreeze(false);
enablePatches();
root.render(
  <Provider store={store}>
    <BrowserRouter history={history}>
      <MapProvider>
        <AppLazy />
      </MapProvider>
      /
    </BrowserRouter>
  </Provider>
);

const stopHotReload = true;
if (stopHotReload) {
  new EventSource('/esbuild').addEventListener('change', () => location.reload());
}
