// import 'leaflet/dist/leaflet';
import 'leaflet-draw/dist/leaflet.draw.css';
import 'leaflet/dist/leaflet.css';
import 'leaflet-editable/src/Leaflet.Editable';
import 'leaflet.path.drag/src/Path.Drag';
import 'leaflet-draw';
export const mapInit = () => {
  const script = document.createElement('script');
  const apiKey = process.env.GOOGLE_API_KEY;
  script.src = `https://maps.googleapis.com/maps/api/js?key=${apiKey}&libraries=places&callback=initAutocomplete`;
  script.async = true;
  script.defer = true;
  document.body.appendChild(script);
  //await import('@geoman-io/leaflet-geoman-free');
  if (window.initAutocomplete === undefined) {
    window.initAutocomplete = () => null;
  }
};
