import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Select, Badge } from 'antd';
import { history, URL_CONSTANTS } from '@utils/history';
import { currentFeatureSet, isEnabled } from '@selectors/featureSet';
import { getActiveReseller } from '@selectors/headerSelectors';
import { DropdownDefaultTitleDiv, DropdownDefaultTitle, DropdownDefaultPlacholder } from './styles';
import { AvatarComponent } from '../../common/Avatar/Avatar';
import { CustomDropdown } from './commonComponents';

export const ResellerSelect = (props) => {
  const { t } = useTranslation();
  const featureSet = useSelector(currentFeatureSet);
  const activeResellers = useSelector((state) => getActiveReseller(state));
  const domElement = {};
  const { Option } = Select;
  const {
    resellersList,
    isRecommendationLoad,
    isAllFieldLoaded,
    setCurrentOrg,
    setLatLongorgReset,
    setCurrentReseller,
    currentReseller
  } = props;

  const updateReseller = useCallback(
    (resObj) => {
      const setOrgList = () => {
        setCurrentOrg(undefined);
        const activeResellerList = activeResellers;
        activeResellerList.push(resObj[0]);
        setCurrentReseller(resObj[0], activeResellerList);
        const manageOrganization = isEnabled('ManageOrganization', featureSet);
        history.push(manageOrganization ? URL_CONSTANTS.DEALER() : URL_CONSTANTS.CROP());
      };
      setOrgList();
    },
    [featureSet, setCurrentReseller]
  );

  const onResellerChange = useCallback(
    (val, options) => {
      if (val !== 'default') {
        let resObj = resellersList.filter(
          (resellers) => resellers.id === Number(options.key) || resellers.id === options.key
        );
        updateReseller(resObj);
      }
      setTimeout(() => {
        let select = document.querySelector(
          '[data-testid=collapsedHeader-select-reseller] .ant-select-selection-search-input'
        );
        select.blur();
      }, 500);
    },
    [resellersList, setLatLongorgReset, updateReseller]
  );

  return (
    <Select
      data-testid='collapsedHeader-select-reseller'
      dropdownMatchSelectWidth={true}
      disabled={isRecommendationLoad || isAllFieldLoaded}
      showSearch
      onFocus={() => {
        const orgElement = document.querySelector(
          '[data-testid="collapsedHeader-select-reseller"]'
        );
        const selectedElement = orgElement.querySelector('.ant-select-selection-item');
        domElement.orgDropDown = selectedElement.cloneNode(true);
        domElement.orgDropDown.id = 'cloned_reseller_div';
      }}
      onInputKeyDown={() => {
        const selectItem = document.getElementById('cloned_reseller_div');
        if (!selectItem) {
          document
            .querySelector('[data-testid="collapsedHeader-select-reseller"]')
            .querySelector('[class="ant-select-selector"]')
            .appendChild(domElement.orgDropDown);
        }
      }}
      onBlur={() => {
        const selectItem = document.getElementById('cloned_reseller_div');
        if (selectItem) {
          selectItem.remove();
        }
      }}
      optionFilterProp='children'
      filterOption={(input, option) => {
        return option.props.value.toLowerCase().indexOf(input.toLowerCase()) >= 0;
      }}
      size='large'
      className='orgSelect'
      onSelect={onResellerChange}
      bordered={false}
      value={currentReseller.name || 'default'}
      dropdownStyle={{ borderRadius: 8, left: 90, top: 60 }}
      style={{ width: 260, margin: '0 0.5em' }}
      dropdownRender={(menu) => <CustomDropdown menu={menu} t={t} label={'My Reps/Reseller'} />}
    >
      {!currentReseller.email && (
        <Option hidden key='default' value='default'>
          <div style={{ display: 'flex', padding: '10px 0' }}>
            <AvatarComponent
              style={{ height: 55, width: 40 }}
              src={''}
              alt={t('Organization Image')}
              fontIconName='company'
              fontIconSize='28'
              fontIconColor='#198746'
              color='#FFFFFF'
              height={'40px'}
              width={'40px'}
            />
            <DropdownDefaultTitleDiv className='dropdownDefaultTitleDiv'>
              <DropdownDefaultTitle>{t('Rep/Reseller')}</DropdownDefaultTitle>
              <DropdownDefaultPlacholder>{t('Please Select Reseller')}</DropdownDefaultPlacholder>
            </DropdownDefaultTitleDiv>
          </div>
        </Option>
      )}
      {resellersList.map((item) => (
        <Option className='orgSelectOption' key={item.id} value={item.name}>
          <div style={{ display: 'flex', padding: '10px 0' }}>
            <Badge>
              <AvatarComponent
                style={{ height: 55, width: 40 }}
                src={item && item.picture_url}
                alt={t('Organization Image')}
                fontIconName='company'
                fontIconSize='28'
                fontIconColor='#198746'
                fontIconStyle={{ lineHeight: '40px' }}
                color='#FFFFFF'
                height={'40px'}
                width={'40px'}
              />
            </Badge>
            <DropdownDefaultTitleDiv>
              <DropdownDefaultTitle>{t('Rep/Reseller')}</DropdownDefaultTitle>
              <DropdownDefaultPlacholder
                style={{
                  width: '200px'
                }}
                title={item.name}
              >
                {item.name}
              </DropdownDefaultPlacholder>
            </DropdownDefaultTitleDiv>
          </div>
        </Option>
      ))}
    </Select>
  );
};

ResellerSelect.propTypes = {
  resellersList: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      sourceId: PropTypes.string.isRequired,
      email: PropTypes.string.isRequired,
      cropWiseId: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      type: PropTypes.string.isRequired,
      totalGrowerAssociated: PropTypes.number.isRequired
    })
  ).isRequired,
  isRecommendationLoad: PropTypes.any,
  isAllFieldLoaded: PropTypes.any,
  setCurrentOrg: PropTypes.any,
  setLatLongorgReset: PropTypes.any,
  setCurrentReseller: PropTypes.shape({
    id: PropTypes.number.isRequired,
    sourceId: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    cropWiseId: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    totalGrowerAssociated: PropTypes.number.isRequired
  }),
  currentReseller: PropTypes.shape({
    id: PropTypes.number.isRequired,
    sourceId: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    cropWiseId: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    totalGrowerAssociated: PropTypes.number.isRequired
  })
};
