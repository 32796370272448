import React, { useEffect } from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 900;
  display: flex;
  align-items: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 50px;
  overflow: hidden;
`;

const Button = styled.button`
  background-color: #17494d;
  color: white;
  border: none;
  border-radius: 50px;
  padding: 12px 16px;
  font-weight: bold;
  display: flex;
  align-items: center;
  cursor: pointer;
  transition: background-color 0.3s;

  &:hover {
    background-color: #17494d;
  }
`;

const IconContainer = styled.span`
  margin-right: 8px;
`;

const Label = styled.span`
  white-space: nowrap;
`;

const SupportButton = () => {
  useEffect(() => {
    const iframe = document.getElementById('launcher');
    const originalDisplay = iframe ? iframe.style.display : '';
    if (iframe) {
      iframe.style.display = 'none';
    }
    return () => {
      if (iframe) {
        iframe.style.display = originalDisplay;
      }
    };
  }, []);

  return (
    <Wrapper>
      <Button
        data-testid='launcher'
        aria-label='Support'
        onClick={() =>
          window.open(
            atob(
              'aHR0cHM6Ly9zeW5nZW50YS5zaGFyZXBvaW50LmNvbS9zaXRlcy9FdXJvcGVTZWVkU2VsZWN0b3IvU2l0ZVBhZ2VzL1NlZWQtU2VsZWN0b3ItLVJVLSVEMCU5MSVEMCVCMCVEMCVCNyVEMCVCMC0lRDAlQjclRDAlQkQlRDAlQjAlRDAlQkQlRDAlQjglRDAlQjkuYXNweA=='
            ),
            '_blank'
          )
        }
      >
        <IconContainer data-testid='Icon'>
          <svg
            width='20'
            height='18'
            viewBox='0 0 20 18'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
          >
            <path
              fillRule='evenodd'
              clipRule='evenodd'
              d='M10 0C14.74 0 19 3.73 19 9.22C19.59 9.53 20 10.14 20 10.84V13.14C20 13.86 19.59 14.47 19 14.78V16C19 17.1 18.1 18 17 18H9V16H17V8.9C17 5.03 13.87 1.9 10 1.9C6.13 1.9 3 5.03 3 8.9V15H2C0.9 15 0 14.1 0 13V11C0 10.26 0.4 9.62 1 9.28C1 3.65 5.31 0 10 0ZM8 10C8 10.5523 7.55228 11 7 11C6.44772 11 6 10.5523 6 10C6 9.44771 6.44772 9 7 9C7.55228 9 8 9.44771 8 10ZM13 11C13.5523 11 14 10.5523 14 10C14 9.44771 13.5523 9 13 9C12.4477 9 12 9.44771 12 10C12 10.5523 12.4477 11 13 11ZM10.0505 3C13.0405 3 15.5205 5.18 16.0005 8.03C12.8805 8 10.1905 6.19 8.88046 3.56C8.35046 6.24 6.49046 8.44 4.02046 9.45C3.76046 5.51 7.02046 3 10.0505 3Z'
              fill='white'
            />
          </svg>
        </IconContainer>
        <Label data-testid='launcher-label'>Support</Label>
      </Button>
    </Wrapper>
  );
};

export default SupportButton;
