import React from 'react';
import { Avatar } from 'antd';
import PropTypes from 'prop-types';
import { FontIcon } from '@common/IconComponent';

export const AvatarComponent = (props) => {
  const {
    fontIconName,
    fontIconSize,
    fontIconColor,
    fontIconStyle,
    color,
    height = 'auto',
    width = 'auto'
  } = props;

  return (
    <Avatar
      style={{ backgroundColor: color, height: height, width: width }}
      icon={
        <FontIcon
          name={fontIconName}
          size={fontIconSize}
          color={fontIconColor}
          style={fontIconStyle}
        />
      }
    />
  );
};

AvatarComponent.propTypes = {
  fontIconName: PropTypes.any,
  fontIconSize: PropTypes.any,
  fontIconColor: PropTypes.any,
  fontIconStyle: PropTypes.any,
  color: PropTypes.any,
  height: PropTypes.any,
  width: PropTypes.any
};
