import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { getSelectedOrganization } from '../selectors/headerSelectors';
import { UserProfile } from '../actions/LoginActions';
import { getallOrgsList } from '../selectors/organizationListSelector';
/**
 *
 * Manages zendesk integration. Zendesk is a way for users to get help in the app.
 *
 * Only use this hook in one place.
 * This hook assumes window.zE has been loaded.
 *
 * Documentation:
 * https://developer.zendesk.com/api-reference/widget/introduction/
 *
 * @returns {}
 *    zendeskEnabled will tell you if zendesk is enabled for the selected organization's country.
 */
export const useZendesk = () => {
  const user: UserProfile = useSelector((state: any) => {
    return state.user.userProfile as UserProfile;
  });
  const { zendeskEnabled, country, org } = useZendeskEnabled();

  // On initial startup, hide it.
  useEffect(() => {
    try {
      window.zE('webWidget', 'hide');
      // eslint-disable-next-line no-empty
    } catch (e) {}
  }, []);
  const lng =
    localStorage.getItem('LangParams') === null ? 'en' : localStorage.getItem('LangParams');
  // Toggle and configure the widget.
  useEffect(() => {
    try {
      if (zendeskEnabled) {
        window.zE('webWidget', 'show');
        drawChatIcon();
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        updateZendeskConfiguration(user, country, org);
        if (document.getElementById('launcher') !== null) {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore: Object is possibly 'null'.
          document.getElementById('launcher').style.zIndex = '1000';
        }
      } else {
        window.zE('webWidget', 'hide');
      }
      // eslint-disable-next-line no-empty
    } catch (e) {}
  }, [user, zendeskEnabled, lng, country]);

  return { zendeskEnabled };
};
/**
 * Determines if zendesk should be enabled for the selected organization's country.
 */
const useZendeskEnabled = () => {
  const selectedOrganization = useSelector(getSelectedOrganization);
  const orgs = useSelector(getallOrgsList);
  const [zendeskEnabled, setZendeskEnabled] = useState(false);
  const [updateCountry, updatedCountry] = useState('russia');
  const [orgItem, setOrgItem] = useState({});
  const user: UserProfile = useSelector((state: any) => {
    return state.user.userProfile as UserProfile;
  });

  useEffect(() => {
    if (!orgs || !selectedOrganization || !user) {
      setZendeskEnabled(false);
      return;
    }

    // Get the selected org object.
    const selected = orgs.find((o: any) => o.id === selectedOrganization);
    if (!selected) {
      setZendeskEnabled(false);
      return;
    }
    setOrgItem(selected);
    updatedCountry(selected.country);
    const countryList = [
      'Bulgaria',
      'Germany',
      'Czech Republic',
      'Hungary',
      'Italy',
      'Poland',
      'Romania',
      'Russian Federation',
      'Russia',
      'Ukraine',
      'United Kingdom',
      'England',
      'Slovakia',
      'Serbia',
      'Spain',
      'Turkey'
    ];
    setZendeskEnabled(countryList.includes(selected.country));
  }, [orgs, selectedOrganization, user]);

  return { zendeskEnabled, country: updateCountry, org: orgItem };
};

/**
 * Wrapper around zE commands to open the widget.
 */
export const openZendeskWidget = () => {
  if (!window.zE) {
    return;
  }

  window.zE('webWidget', 'show');
  window.zE('webWidget', 'open');
};

/**
 * zE API does not expose a way to use an SVG as the icon for the launcher so we manually redraw.
 */
const drawChatIcon = () => {
  const iframe = document.getElementById('launcher');
  if (!iframe) {
    return;
  }
  iframe.style.zIndex = '999';
  (iframe as any).contentWindow.document.getElementsByTagName('span')[0].innerHTML = `
    <svg
      width="20"
      height="18"
      viewBox="0 0 20 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M10 0C14.74 0 19 3.73 19 9.22C19.59 9.53 20 10.14 20 10.84V13.14C20 13.86 19.59 14.47 19 14.78V16C19 17.1 18.1 18 17 18H9V16H17V8.9C17 5.03 13.87 1.9 10 1.9C6.13 1.9 3 5.03 3 8.9V15H2C0.9 15 0 14.1 0 13V11C0 10.26 0.4 9.62 1 9.28C1 3.65 5.31 0 10 0ZM8 10C8 10.5523 7.55228 11 7 11C6.44772 11 6 10.5523 6 10C6 9.44771 6.44772 9 7 9C7.55228 9 8 9.44771 8 10ZM13 11C13.5523 11 14 10.5523 14 10C14 9.44771 13.5523 9 13 9C12.4477 9 12 9.44771 12 10C12 10.5523 12.4477 11 13 11ZM10.0505 3C13.0405 3 15.5205 5.18 16.0005 8.03C12.8805 8 10.1905 6.19 8.88046 3.56C8.35046 6.24 6.49046 8.44 4.02046 9.45C3.76046 5.51 7.02046 3 10.0505 3Z"
        fill="white"
      ></path>
    </svg>`;
};

/**
 * Apply our custom settings to the widget.
 */
const updateZendeskConfiguration = (
  user: { name: string; email: string; country: string; id: string },
  country: string,
  org: any
) => {
  if (!window.zE) {
    return;
  }

  const lng =
    localStorage.getItem('LangParams') === null ? 'en' : localStorage.getItem('LangParams');
  const countryName =
    country === 'Russian Federation' ? 'russia_eame' : `${country.toLowerCase()}_eame`;

  const countryNameCategory = '23701891623827-Seed-Selector-FAQ';

  const webWidget = {
    contactForm: {
      fields: [
        {
          id: 22365850843283,
          prefill: { '*': 'europeseedsproduct_salesrep_seed_selector' }
        },
        { id: 4415587591827, prefill: { '*': countryName } },
        { id: 1900004951125, prefill: { '*': process.env.envName } }
      ]
    },
    helpCenter: {
      filter: {
        category: countryNameCategory
      }
    }
  };

  const localeMap = {
    ru: 'ru',
    bg: 'bg-bg',
    cs: 'cs-cz',
    fr: 'fr',
    de: 'de-de',
    hu: 'hu-hu',
    it: 'it-it',
    pl: 'pl-pl',
    pt: 'pt',
    ro: 'ro-ro',
    sk: 'sk-sk',
    es: 'es-es',
    uk: 'uk',
    sr: 'sr',
    'tr-TR': 'tr',
    en: 'en-us',
    'en-GB': 'en-gb'
  };

  const locale = localeMap[lng];

  if (locale) {
    window.zE('webWidget', 'setLocale', locale);
  }

  webWidget.helpCenter.filter.category = '23701891623827-Seed-Selector-FAQ';
  window.zE('webWidget', 'updateSettings', {
    webWidget: { ...webWidget }
  });

  window.zE('webWidget', 'prefill', {
    name: {
      value: user.name
    },
    email: {
      value: user.email
    }
  });
};
