/* eslint-disable @typescript-eslint/no-unsafe-return */
import { Badge, Button, notification, Popover, Spin } from 'antd';
import { ArgsProps } from 'antd/lib/notification';
import moment from 'moment';
import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { connect, ConnectedProps } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  fetchNotificationsByReseller,
  getDictionaryForIds,
  IdDictionary,
  markNotificationsAsRead
} from '@actions/notificationActions';
import { getSelectedOrganization, getSelectedReseller } from '@selectors/headerSelectors';
import {
  getResellerNotifications,
  isFetchingMarkAsRead,
  isFetchingNotificationsByEmail
} from '@selectors/notificationsSelector';
import { PlantingPlanEvents } from '../../features/Planting/LogEvents';
import { StyledInitialName } from './styles';
import { CloseIcon } from '../../common/Icons';
import { amplitudeLogEvent } from '@utils/amplitudeEvent';

/**
 * Map redux data to our props
 */
const mapStateToProps = (state: {}) => ({
  fetchingNotificationsByEmail: isFetchingNotificationsByEmail(state),
  fetchingMarkAsRead: isFetchingMarkAsRead(state),
  currentReseller: getSelectedReseller(state),
  notifications: getResellerNotifications(state),
  orgId: getSelectedOrganization(state)
});

/**
 * Map any dispatch actions to props
 */
const mapDispatchToProps = (dispatch) => ({
  fetchNotifications: () => dispatch(fetchNotificationsByReseller()),
  markReadNotifications: (dictionary: IdDictionary) => dispatch(markNotificationsAsRead(dictionary))
});

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

const MAX_DISPLAYED_NOTIFICATIONS = 3;

const NotificationCloseButton = (props: { markAsRead: () => unknown }) => {
  return <CloseIcon onClick={props.markAsRead} />;
};

const NotificationMenu = (props: PropsFromRedux) => {
  const { i18n, t } = useTranslation();
  const {
    currentReseller,
    fetchingMarkAsRead,
    fetchingNotificationsByEmail,
    fetchNotifications,
    markReadNotifications,
    notifications,
    orgId
  } = props;
  const fetching = fetchingMarkAsRead || fetchingNotificationsByEmail;
  const unreadCount = useMemo(
    () =>
      notifications
        ? notifications.filter((n) => n.status === 'unread' && n.action !== 'Go to Planting Plan')
            .length
        : 0,
    [notifications]
  );
  const notified = useRef(notifications);

  const history = useNavigate();

  const navigateFromNotificationButton = useCallback(
    (url: string) => {
      history(url);
    },
    [currentReseller, history, orgId]
  );

  /** Potentially fetch notifications when one of these values changes, rather than on every render */
  useEffect(() => {
    if (!fetchingNotificationsByEmail && !notifications && currentReseller?.email) {
      fetchNotifications();
    }
  }, [currentReseller, fetchingNotificationsByEmail, fetchNotifications, notifications]);

  /** Display popup notifications in bottom left of screen */
  useEffect(() => {
    const { current: previouslyNotified } = notified;
    if (!previouslyNotified?.length) {
      notified.current = notifications;
      return;
    }
    if (!notifications?.length) {
      return;
    }

    const baseNotificationProps: ArgsProps = {
      message: `${t('New Notification')}`,
      placement: 'bottomLeft',
      duration: 10
    };

    const notifiedIds = previouslyNotified.map((n) => n.id);
    const newNotifications = notifications.filter((n) => !notifiedIds.includes(n.id));
    const toDisplay = newNotifications.slice(0, MAX_DISPLAYED_NOTIFICATIONS);
    toDisplay.forEach((newNotification) => {
      const { action, id, localizablePayload, notificationText, url } = newNotification;
      let notificationString = notificationText;
      if (localizablePayload) {
        // if there is a localizable payload, translate it and use it as the displayed string for the notification
        notificationString = t(localizablePayload.key, localizablePayload.data);
      } else if (action === 'Go to Solution') {
        try {
          notificationString = JSON.parse(notificationText).message;
        } catch (e) {
          notificationString = notificationText;
        }
      }
      // add the dropdown notification functionality to the popup notifications
      const description = (
        <div>
          <h4>{notificationString}</h4>
          {action && url ? (
            <Button
              onClick={() => {
                markReadNotifications({ [id]: true });
                navigateFromNotificationButton(url);
              }}
            >
              {`${t(action)}`}
            </Button>
          ) : (
            <></>
          )}
        </div>
      );
      const markAsRead = () => markReadNotifications({ [id]: true });
      const fullNotificationProps: ArgsProps = {
        ...baseNotificationProps,
        description: description,
        onClick: markAsRead,
        // customize closeIcon instead of using onClose here because onClose triggers at automatic close after display duration
        closeIcon: <NotificationCloseButton markAsRead={markAsRead} />
      };
      if (notificationText.toLocaleLowerCase().indexOf('fail') >= 0) {
        notification.error(fullNotificationProps);
      } else {
        if (newNotifications[0].action !== 'Go to Planting Plan') {
          notification.info(fullNotificationProps);
        }
      }
    });
    // if we reduced the number of visible notifications, alert the user that some notifications were not displayed
    if (newNotifications.length > MAX_DISPLAYED_NOTIFICATIONS) {
      const message = t('see notifications for {{numberMoreMessages}} more messages', {
        numberMoreMessages: newNotifications.length - MAX_DISPLAYED_NOTIFICATIONS
      });
      notification.info({
        ...baseNotificationProps,
        description: message
      });
    }
    // mark ALL new notifications as displayed to the user, even if they were part of the "other items" group because it is an annoying user experience to have notifications continue to pop up on every module change
    notified.current = notifications;
  }, [markReadNotifications, navigateFromNotificationButton, notifications, t]);

  const markAllAsRead = useCallback(() => {
    if (!notifications) {
      return;
    }
    const unreadIds = notifications.filter((n) => n.status === 'unread').map((n) => n.id);
    markReadNotifications(getDictionaryForIds(unreadIds));
  }, [markReadNotifications, notifications]);

  return (
    <Popover
      className='notificationMenuPopoverBtn'
      placement='bottomRight'
      content={
        <Spin spinning={fetching}>
          <div
            style={{
              width: 515,
              height: 484,
              background: '#FFFFFF',
              boxShadow: '0px 4px 12px rgba(112, 115, 116, 0.3)',
              borderRadius: 8,
              position: 'relative'
            }}
          >
            <div
              style={{
                position: 'absolute',
                top: 16,
                left: 16,
                right: 16,
                height: 44,
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'baseline',
                borderBottom: '1px solid #C1C5C8'
              }}
            >
              <h2>{t('Notifications')}</h2>
              <button
                onClick={markAllAsRead}
                style={{
                  background: 'none',
                  color: '#009933',
                  border: 'none',
                  padding: 0,
                  font: 'inherit',
                  cursor: 'pointer'
                }}
              >
                {t('Mark all as read')}
              </button>
            </div>
            <div
              style={{
                position: 'absolute',
                top: 60,
                bottom: 16,
                left: 16,
                right: 16,
                overflow: 'auto',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'stretch',
                justifyContent: 'flex-start'
              }}
            >
              {fetchingNotificationsByEmail ? (
                <div style={{ marginTop: 20, textAlign: 'center' }}>
                  {`${t('Loading notifications...')}`}
                </div>
              ) : (
                <></>
              )}
              {!fetchingNotificationsByEmail && notifications && !notifications.length ? (
                <div style={{ marginTop: 20, textAlign: 'center' }}>
                  {`${t('You currently have no notifications')}`}
                </div>
              ) : (
                <></>
              )}
              {notifications?.map((n) => {
                const {
                  action,
                  avatar,
                  id,
                  localizablePayload,
                  notificationText,
                  status,
                  url,
                  userTimestamp
                } = n;
                let notificationString;
                if (action !== 'Go to Planting Plan') {
                  if (localizablePayload) {
                    notificationString = t(localizablePayload.key, localizablePayload.data);
                  } else if (action === 'Go to Solution') {
                    notificationString = JSON.parse(notificationText)?.message ?? notificationText;
                  } else {
                    notificationString = notificationText;
                  }
                  return (
                    <button
                      className='notificationContainer'
                      key={id}
                      onClick={() => {
                        markReadNotifications({ [id]: true });
                      }}
                      style={{
                        cursor: 'pointer',
                        display: 'flex',
                        flexDirection: 'row',
                        background: 'none',
                        border: 'none',
                        font: 'inherit',
                        padding: 0,
                        textAlign: 'left'
                      }}
                    >
                      <div style={{ padding: 16 }}>
                        <Badge count={status === 'unread' ? 1 : 0} dot>
                          <StyledInitialName>{avatar ?? 'NS'}</StyledInitialName>
                        </Badge>
                      </div>
                      <div style={{ flexGrow: 1, padding: 16, paddingLeft: 0 }}>
                        <h4>{notificationString}</h4>
                        <h5>
                          {userTimestamp
                            ? moment(userTimestamp).locale(i18n.language).fromNow()
                            : ''}
                          {userTimestamp
                            ? ` (${moment(userTimestamp).locale(i18n.language).format('lll')})`
                            : ''}
                        </h5>
                        {action && url ? (
                          <Button
                            type='primary'
                            onClick={() => {
                              navigateFromNotificationButton(url);
                            }}
                          >
                            {t(action)}
                          </Button>
                        ) : (
                          <></>
                        )}
                      </div>
                    </button>
                  );
                }
              })}
            </div>
          </div>
        </Spin>
      }
      style={{ marginRight: '20px' }}
      /** This trigger prop claims to be an error, but it is not. Ant Design 3.x documentation indicates that trigger will only accept a
       * single string, not an array, but I tried an array anyway because an array works for Dropdown elements. It seems to work.
       * ~ eragan, Skyward, 21 October 2021 */
      trigger={['click']}
    >
      <div
        className='notificationButtonContainer'
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          width: 40,
          marginRight: 8
        }}
      >
        <Badge count={unreadCount}>
          <svg
            width='24'
            height='24'
            viewBox='0 0 24 24'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
          >
            <path
              fillRule='evenodd'
              clipRule='evenodd'
              d='M20.8742 17.9874C21.1275 17.3787 20.9914 16.6777 20.5287 16.2077L18.7268 14.3977V8.93774C18.7764 5.51308 16.2898 2.57662 12.9009 2.05774C10.9767 1.80459 9.03659 2.3915 7.57622 3.6685C6.11585 4.9455 5.2767 6.78891 5.27315 8.72774V14.3977L3.47132 16.2077C3.00861 16.6777 2.87252 17.3787 3.12585 17.9874C3.37917 18.596 3.97266 18.994 4.6325 18.9977H19.3675C20.0273 18.994 20.6208 18.596 20.8742 17.9874ZM17.3094 15.8087C16.9363 15.4339 16.7268 14.9266 16.7268 14.3977L16.7271 8.90879C16.7622 6.48308 15.0003 4.40249 12.64 4.04065C11.2857 3.86248 9.92031 4.27554 8.89275 5.17407C7.86567 6.07219 7.27564 7.36833 7.27315 8.72774V14.3977C7.27315 14.9266 7.06368 15.4339 6.69056 15.8087L5.50691 16.9978L16.0041 16.9977L18.4931 16.9978L17.3094 15.8087ZM14.002 20.3377C13.8867 21.337 13 22 12 21.9977C11 21.9955 10.1133 21.337 9.99796 20.3377C9.99796 20.15 10.1502 19.9977 10.338 19.9977H13.662C13.8498 19.9977 14.002 20.15 14.002 20.3377Z'
              fill='#707374'
            />
          </svg>
        </Badge>
      </div>
    </Popover>
  );
};
export const ConnectedNotificationMenu = connector(NotificationMenu);
