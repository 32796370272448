import React from 'react';
import styled from 'styled-components';
import ukLogoImg from '../../public/SeedSelectorIcon.svg';

const Logo = styled.img`
  height: 60px;
  padding: 10px 0;
  /* width: 192px; */
`;
export const UKLogoIcon = (props) => <Logo src={ukLogoImg} {...props} />;
