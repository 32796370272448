import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { Popover, List } from 'antd';
import { PoweroffOutlined } from '@ant-design/icons';
import { getInitialName } from '@utils/common-methods';
import { toggleUserProfileState } from '@actions/index';
import { isOpenUserProfilePopup } from '@selectors/headerSelectors';
import { isAlloweduserForChangeRole } from '@selectors/userProfileSelector';
import { ListStyle, PopoverSelectStyle, StyledAnchorLink, StyledInitialName } from './styles';
import LanguageDropdown from '../../features/localization/LanguageDropdown';
import CropwiseBaseService from '../../services/CropwiseBaseService';

export const UserProfileSelect = (props) => {
  const { userProfile, handleLogout } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const isUserProfilePopupToggle = useSelector(isOpenUserProfilePopup);
  const isUserValidForChangeRole = useSelector(isAlloweduserForChangeRole);
  const [isUserPopupOpen, setIsUserPopupOpen] = useState(false);

  useEffect(() => {
    if (isUserProfilePopupToggle) {
      setIsUserPopupOpen(isUserProfilePopupToggle);
      setIsUserPopupOpen(false);
      dispatch(toggleUserProfileState(false));
    }
  }, [isUserProfilePopupToggle]);

  const changeRole = async (userRole) => {
    try {
      CropwiseBaseService.updateUser({ id: userProfile.id, role: userRole });
      handleLogout();
    } catch (e) {
      console.warn('something went wrong', e);
    }
  };

  const useritems = [
    {
      key: '2',
      datatestid: 'header-popupmenuList-item-language',
      label: t('Language'),
      title: t('Supported Language'),
      style: { display: 'flex', borderBottom: '0px' },
      content: (
        <label style={{ display: 'flex', color: '#707374' }}>
          <span>{t('Language')}</span>
          <LanguageDropdown type='DROPDOWN' />
        </label>
      )
    },
    {
      key: 'changeRole',
      datatestid: 'header-popupmenuList-item-change-role',
      label: 'Change Role',
      title: t('Change Role'),
      style: { borderBottom: '0px' },
      content: (
        <label style={{ color: '#707374' }}>
          <span>{t('Change Role: ')}</span>
          <PopoverSelectStyle
            defaultValue={userProfile.role}
            onChange={changeRole}
            options={[
              {
                value: 'ADMIN',
                label: 'Admin'
              },
              {
                value: 'DEALER',
                label: 'Dealer'
              },
              {
                value: 'MANAGER',
                label: 'Manager'
              }
            ]}
          />
        </label>
      )
    },
    {
      key: '4',
      title: 'Logout',
      onClick: () => handleLogout(),
      datatestid: 'header-popupmenuList-item-logout',
      content: (
        <StyledAnchorLink className='logoutLink' onClick={handleLogout}>
          <PoweroffOutlined />
          <span style={{ margin: '0 10px' }}>{t('Logout')}</span>
        </StyledAnchorLink>
      )
    }
  ];

  const getItems = () => {
    if (userProfile && isUserValidForChangeRole) {
      return useritems;
    } else {
      return useritems.filter((item) => item.key !== 'changeRole');
    }
  };

  const Usermenu = (
    <ListStyle
      data-testid='header-popupmenuList'
      style={{ fontWeight: 500, color: '#707374' }}
      className='popupmenuList'
      dataSource={getItems()}
      renderItem={(item) => (
        <List.Item
          key={item.key}
          style={item.style}
          title={item.title}
          data-testid={item.datatestid}
        >
          {item.content}
        </List.Item>
      )}
    />
  );
  return (
    <Popover
      data-testid='collapsedHeader-profile'
      className='rightMenuPopoverbtn'
      content={isUserPopupOpen ? Usermenu : null}
      placement='bottomRight'
      open={isUserPopupOpen}
      onOpenChange={(e) => {
        setIsUserPopupOpen(e);
      }}
      trigger='click'
    >
      <StyledInitialName data-testid='profile-name'>
        {getInitialName(userProfile.name)}
      </StyledInitialName>
    </Popover>
  );
};

UserProfileSelect.propTypes = {
  userProfile: PropTypes.any,
  handleLogout: PropTypes.any
};
