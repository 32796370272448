import React from 'react';
import { StyledDropdownDiv } from './styles';
import { Divider } from 'antd';

export const CustomDropdown = (props) => {
  const { menu, t, label } = props;
  return (
    <StyledDropdownDiv className='dropDownRender'>
      <h3 style={{ padding: '8px 0 0 10px' }}>{t(label)}</h3>
      {menu}
      <Divider />
    </StyledDropdownDiv>
  );
};
